/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ProblemDto, TenderDto, TenderPageDto, TenderSearchRequestDto } from "./data-contracts";
import {HttpClient, HttpResponse, RequestParams} from "./http-client";

export class Tenders<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Admin. Тендеры
   * @name RefreshTender
   * @summary Обновить данные тендера
   * @request POST:/api/admin/tenders/{tenderId}/refresh
   */
  refreshTender = (tenderId: string, params: RequestParams = {}) =>
    this.request<TenderDto, any>({
      path: `/api/admin/tenders/${tenderId}/refresh`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Тендеры
   * @name PrepareProblem
   * @summary Подготовить данные для проблем
   * @request POST:/api/admin/tenders/{tenderId}/problems/create
   */
  prepareProblem = (tenderId: string, params: RequestParams = {}) =>
    this.request<ProblemDto, any>({
      path: `/api/admin/tenders/${tenderId}/problems/create`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Тендеры
   * @name GetTenders
   * @summary Получить список тендеров
   * @request GET:/api/admin/tenders
   */
  getTenders = (
    query: TenderSearchRequestDto,
    params: RequestParams = {},
  ) :Promise<HttpResponse<TenderPageDto, any>> =>
    this.request<TenderPageDto, any>({
      path: `/api/admin/tenders`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Тендеры
   * @name GetTender
   * @summary Получить тендер по id
   * @request GET:/api/admin/tenders/{tenderId}
   */
  getTender = (tenderId: string, params: RequestParams = {}) =>
    this.request<TenderDto, any>({
      path: `/api/admin/tenders/${tenderId}`,
      method: "GET",
      ...params,
    });
}
