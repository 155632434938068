/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PageDtoProblemCategoryDto,
  PageDtoProblemCommentDto,
  PageDtoProblemDto,
  PageDtoProblemPaymentDto,
  PageDtoProblemResolverDto,
  PageDtoProblemSurveyExtendedDto,
  PageDtoProblemUserDto,
  PageRequestDto,
  ProblemCategoryCreateDto,
  ProblemCategoryDto,
  ProblemCreateDto,
  ProblemDto,
  ProblemEventCreateDto,
  ProblemEventDto,
  ProblemExtendedDto,
  ProblemFileDto,
  ProblemResolverChangeStatusDto,
  ProblemResolverDto,
  ProblemSurveyCreateDto,
  ProblemSurveyDto,
  ProblemSurveyExtendedDto,
  ProblemSurveyOptionCreateDto,
  ProblemSurveyUpdateDto,
  ProblemUserCreateDto,
  ProblemUserDto,
  UploadEventFile2ParamsTypeEnum,
  UploadProblemFileParamsTypeEnum,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Problems<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name GetOneProblem
   * @summary Детальная информация по проблеме
   * @request GET:/api/admin/problems/{problemId}
   */
  getOneProblem = (problemId: number, params: RequestParams = {}) =>
    this.request<ProblemExtendedDto, any>({
      path: `/api/admin/problems/${problemId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name UpdateProblem2
   * @summary Обновить проблему
   * @request PUT:/api/admin/problems/{problemId}
   */
  updateProblem = (problemId: number, data: ProblemCreateDto, params: RequestParams = {}) =>
    this.request<ProblemDto, any>({
      path: `/api/admin/problems/${problemId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Пользователи участники проблем
   * @name AddProblemUser
   * @summary Добавление участника проблемы
   * @request PUT:/api/admin/problems/{problemId}/user
   */
  addProblemUser = (problemId: number, data: ProblemUserCreateDto, params: RequestParams = {}) =>
    this.request<ProblemUserDto, any>({
      path: `/api/admin/problems/${problemId}/user`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Пользователи участники проблем
   * @name AddProblemUserByUserId
   * @summary Добавление участника проблемы по его id
   * @request PUT:/api/admin/problems/{problemId}/user/{userId}
   */
  addProblemUserByUserId = (problemId: number, userId: string, params: RequestParams = {}) =>
    this.request<ProblemUserDto, any>({
      path: `/api/admin/problems/${problemId}/user/${userId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Пользователи участники проблем
   * @name DeleteProblemUser
   * @summary Удаление участника проблемы
   * @request DELETE:/api/admin/problems/{problemId}/user/{userId}
   */
  deleteProblemUser = (problemId: number, userId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/user/${userId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Пользователи участники проблем
   * @name UpdateOwner
   * @summary Сохранение пользователя которому принадлежит проблема
   * @request PUT:/api/admin/problems/{problemId}/owner
   */
  updateOwner = (problemId: number, data: ProblemUserCreateDto, params: RequestParams = {}) =>
    this.request<ProblemUserDto, any>({
      path: `/api/admin/problems/${problemId}/owner`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Пользователи участники проблем
   * @name UpdateOwnerByUserId
   * @summary Сохранение пользователя которому принадлежит проблема по его id
   * @request PUT:/api/admin/problems/{problemId}/owner/{userId}
   */
  updateOwnerByUserId = (problemId: number, userId: string, params: RequestParams = {}) =>
    this.request<ProblemUserDto, any>({
      path: `/api/admin/problems/${problemId}/owner/${userId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Журнал событий проблем
   * @name UpdateProblemEvent
   * @summary Отредактировать запись в журнале проблемы
   * @request PUT:/api/admin/problems/{problemId}/events/{problemEventId}
   */
  updateProblemEvent = (
    problemId: number,
    problemEventId: number,
    data: ProblemEventCreateDto,
    params: RequestParams = {},
  ) =>
    this.request<ProblemEventDto, any>({
      path: `/api/admin/problems/${problemId}/events/${problemEventId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name FindSurvey
   * @summary Получение опроса по id
   * @request GET:/api/admin/problems/surveys/{problemSurveyId}
   */
  findSurvey = (problemSurveyId: number, params: RequestParams = {}) =>
    this.request<ProblemSurveyExtendedDto, any>({
      path: `/api/admin/problems/surveys/${problemSurveyId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name UpdateSurvey
   * @summary Редактирование опроса
   * @request PUT:/api/admin/problems/surveys/{problemSurveyId}
   */
  updateSurvey = (problemSurveyId: number, data: ProblemSurveyUpdateDto, params: RequestParams = {}) =>
    this.request<ProblemSurveyDto, any>({
      path: `/api/admin/problems/surveys/${problemSurveyId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name DeleteSurvey
   * @summary Удаление опроса
   * @request DELETE:/api/admin/problems/surveys/{problemSurveyId}
   */
  deleteSurvey = (problemSurveyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/surveys/${problemSurveyId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name UpdateSurveyOption
   * @summary Редактирование опции ответа
   * @request PUT:/api/admin/problems/surveys/{problemSurveyId}/options/{optionId}
   */
  updateSurveyOption = (
    problemSurveyId: number,
    optionId: number,
    data: ProblemSurveyOptionCreateDto,
    params: RequestParams = {},
  ) =>
    this.request<ProblemSurveyDto, any>({
      path: `/api/admin/problems/surveys/${problemSurveyId}/options/${optionId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name DeleteSurveyOption
   * @summary Удаление опции опроса
   * @request DELETE:/api/admin/problems/surveys/{problemSurveyId}/options/{optionId}
   */
  deleteSurveyOption = (problemSurveyId: number, optionId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/surveys/${problemSurveyId}/options/${optionId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Категории проблем
   * @name GetCategoryDetails
   * @summary Детализация категории по id
   * @request GET:/api/admin/problems/categories/{problemCategoryId}
   */
  getCategoryDetails = (problemCategoryId: number, params: RequestParams = {}) =>
    this.request<ProblemCategoryDto, any>({
      path: `/api/admin/problems/categories/${problemCategoryId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Категории проблем
   * @name UpdateCategory
   * @summary Редактирование категории проблем
   * @request PUT:/api/admin/problems/categories/{problemCategoryId}
   */
  updateCategory = (problemCategoryId: number, data: ProblemCategoryCreateDto, params: RequestParams = {}) =>
    this.request<ProblemCategoryDto, any>({
      path: `/api/admin/problems/categories/${problemCategoryId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Категории проблем
   * @name DeleteCategory
   * @summary Удалить категорию проблем
   * @request DELETE:/api/admin/problems/categories/{problemCategoryId}
   */
  deleteCategory = (problemCategoryId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/categories/${problemCategoryId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name GetProblemsByIds
   * @summary Получить проблемы по указанным id
   * @request GET:/api/admin/problems
   */
  getProblemsByIds = (
    query: {
      ids: number[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ProblemDto[], any>({
      path: `/api/admin/problems`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name CreateProblem2
   * @summary Создать проблемы
   * @request POST:/api/admin/problems
   */
  createProblem = (data: ProblemCreateDto, params: RequestParams = {}) =>
    this.request<ProblemDto, any>({
      path: `/api/admin/problems`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name SetInProcess
   * @summary Отметить завершение сбора средств и отправить проблему в статус IN_PROCERSS
   * @request POST:/api/admin/problems/{problemId}/set-in-process
   */
  setInProcess = (problemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/set-in-process`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name SendToModeration2
   * @summary Отправить проблему на модерацию
   * @request POST:/api/admin/problems/{problemId}/send-to-moderation
   */
  sendToModeration = (problemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/send-to-moderation`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name RejectResolver
   * @summary Отклонить запрос на решение проблемы
   * @request POST:/api/admin/problems/{problemId}/resolvers/{userId}/reject
   */
  rejectResolver = (
    problemId: number,
    userId: string,
    data: ProblemResolverChangeStatusDto,
    params: RequestParams = {},
  ) =>
    this.request<ProblemResolverDto, any>({
      path: `/api/admin/problems/${problemId}/resolvers/${userId}/reject`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name ApproveResolver
   * @summary Подтвердить запрос на решение проблемы
   * @request POST:/api/admin/problems/{problemId}/resolvers/{userId}/approve
   */
  approveResolver = (
    problemId: number,
    userId: string,
    data: ProblemResolverChangeStatusDto,
    params: RequestParams = {},
  ) =>
    this.request<ProblemResolverDto, any>({
      path: `/api/admin/problems/${problemId}/resolvers/${userId}/approve`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name RejectProblem
   * @summary Отклонить проблему созданную пользователем
   * @request POST:/api/admin/problems/{problemId}/reject
   */
  rejectProblem = (problemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/reject`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Файлы проблем
   * @name GetProblemFiles
   * @summary Информация о файлах загруженных в проблему
   * @request GET:/api/admin/problems/{problemId}/files
   */
  getProblemFiles = (problemId: number, params: RequestParams = {}) =>
    this.request<ProblemFileDto[], any>({
      path: `/api/admin/problems/${problemId}/files`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Файлы проблем
   * @name UploadProblemFile
   * @summary Добавить файл в проблему
   * @request POST:/api/admin/problems/{problemId}/files
   */
  uploadProblemFile = (
    problemId: number,
    query: {
      type: UploadProblemFileParamsTypeEnum;
    },
    data: {
      /** @format binary */
      file: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProblemFileDto, any>({
      path: `/api/admin/problems/${problemId}/files`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Журнал событий проблем
   * @name GetAllEvents
   * @summary Получить журнал по проблеме
   * @request GET:/api/admin/problems/{problemId}/events
   */
  getAllEvents = (problemId: number, params: RequestParams = {}) =>
    this.request<ProblemEventDto[], any>({
      path: `/api/admin/problems/${problemId}/events`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Журнал событий проблем
   * @name CreateProblemEvent
   * @summary Создать запись в журнале проблемы
   * @request POST:/api/admin/problems/{problemId}/events
   */
  createProblemEvent = (problemId: number, data: ProblemEventCreateDto, params: RequestParams = {}) =>
    this.request<ProblemEventDto, any>({
      path: `/api/admin/problems/${problemId}/events`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Журнал событий проблем
   * @name GetEventFiles
   * @summary Список загруженных в запись файлов
   * @request GET:/api/admin/problems/{problemId}/events/{problemEventId}/files
   */
  getEventFiles = (problemEventId: number, problemId: number, params: RequestParams = {}) =>
    this.request<ProblemFileDto[], any>({
      path: `/api/admin/problems/${problemId}/events/${problemEventId}/files`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Журнал событий проблем
   * @name UploadEventFile2
   * @summary Добавить файл в запись
   * @request POST:/api/admin/problems/{problemId}/events/{problemEventId}/files
   */
  uploadEventFile2 = (
    problemId: number,
    problemEventId: number,
    query: {
      type: UploadEventFile2ParamsTypeEnum;
    },
    data: {
      /** @format binary */
      file: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProblemFileDto, any>({
      path: `/api/admin/problems/${problemId}/events/${problemEventId}/files`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name ArchiveProblem
   * @summary Архивировать проблему
   * @request POST:/api/admin/problems/{problemId}/archive
   */
  archiveProblem = (problemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/archive`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name ApproveProblem
   * @summary Подтвердить проблему отправленную на модерацию
   * @request POST:/api/admin/problems/{problemId}/approve
   */
  approveProblem = (problemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/approve`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name CreateSurvey
   * @summary Создание опроса
   * @request POST:/api/admin/problems/surveys
   */
  createSurvey = (data: ProblemSurveyCreateDto, params: RequestParams = {}) =>
    this.request<ProblemSurveyDto, any>({
      path: `/api/admin/problems/surveys`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name AddSurveyOption
   * @summary Добавление опции ответа
   * @request POST:/api/admin/problems/surveys/{problemSurveyId}/options
   */
  addSurveyOption = (problemSurveyId: number, data: ProblemSurveyOptionCreateDto, params: RequestParams = {}) =>
    this.request<ProblemSurveyDto, any>({
      path: `/api/admin/problems/surveys/${problemSurveyId}/options`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Категории проблем
   * @name CreateCategory
   * @summary Создание категории проблем
   * @request POST:/api/admin/problems/categories
   */
  createCategory = (data: ProblemCategoryCreateDto, params: RequestParams = {}) =>
    this.request<ProblemCategoryDto, any>({
      path: `/api/admin/problems/categories`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Пользователи участники проблем
   * @name GetProblemUsers
   * @summary Выборка пользователей проблем
   * @request GET:/api/admin/problems/{problemId}/users
   */
  getProblemUsers = (
    problemId: number,
    query: {
      pageRequest: PageRequestDto;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemUserDto, any>({
      path: `/api/admin/problems/${problemId}/users`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name GetResolvers
   * @summary Получить список людей которые откликнулись на проблему по id проблемы
   * @request GET:/api/admin/problems/{problemId}/resolvers
   */
  getResolvers = (
    problemId: number,
    query?: {
      /**
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * @format int32
       * @default 10
       */
      size?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemResolverDto, any>({
      path: `/api/admin/problems/${problemId}/resolvers`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Платежи
   * @name GetProblemPayments
   * @summary Платежи по проблеме
   * @request GET:/api/admin/problems/{problemId}/payments
   */
  getProblemPayments = (
    problemId: number,
    query: {
      /** @format int32 */
      page: number;
      /**
       * @format int32
       * @max 50
       */
      size: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemPaymentDto, any>({
      path: `/api/admin/problems/${problemId}/payments`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Опросы
   * @name GetSurveys
   * @summary Выборка для таблицы опросов
   * @request GET:/api/admin/problems/surveys/search
   */
  getSurveys = (
    query: {
      request: PageRequestDto;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemSurveyExtendedDto, any>({
      path: `/api/admin/problems/surveys/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name SearchProblems
   * @summary Выборка и фильтрация проблем
   * @request GET:/api/admin/problems/search
   */
  searchProblems = (
    query: {
      request: PageRequestDto;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemDto, any>({
      path: `/api/admin/problems/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name SearchResolvers
   * @summary Получить список людей которые откликнулись на проблемы
   * @request GET:/api/admin/problems/resolvers/search
   */
  searchResolvers = (
    query: {
      requestDto: PageRequestDto;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemResolverDto, any>({
      path: `/api/admin/problems/resolvers/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name SearchResolvers1
   * @summary Получить список людей которые откликнулись на проблемы
   * @request GET:/api/admin/problems/resolvers
   */
  searchResolvers1 = (
    query: {
      requestDto: PageRequestDto;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemResolverDto, any>({
      path: `/api/admin/problems/resolvers`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Платежи
   * @name GetAllProblemPayments
   * @summary Выборка платежей по проблемам
   * @request GET:/api/admin/problems/payments/search
   */
  getAllProblemPayments = (
    query: {
      pageRequest: PageRequestDto;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemPaymentDto, any>({
      path: `/api/admin/problems/payments/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name SearchProblemCommentComments
   * @summary Постраничная выборка всех коментариев к проблемам
   * @request GET:/api/admin/problems/comments/search
   */
  searchProblemCommentComments = (
    query: {
      pageRequestDto: PageRequestDto;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemCommentDto, any>({
      path: `/api/admin/problems/comments/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Категории проблем
   * @name SearchCategories
   * @summary Выборка и фильтрация категорий проблем
   * @request GET:/api/admin/problems/categories/search
   */
  searchCategories = (
    query: PageRequestDto,
    params: RequestParams = {},
  ) =>
    this.request<PageDtoProblemCategoryDto, any>({
      path: `/api/admin/problems/categories/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Файлы проблем
   * @name DeleteProblemFile
   * @summary Удалить файл с проблемы
   * @request DELETE:/api/admin/problems/{problemId}/files/{problemFileId}
   */
  deleteProblemFile = (problemId: number, problemFileId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/files/${problemFileId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы. Журнал событий проблем
   * @name DeleteEventFile
   * @summary Удалить файл с записи
   * @request DELETE:/api/admin/problems/{problemId}/events/{problemEventId}/files/{fileId}
   */
  deleteEventFile = (problemId: number, problemEventId: number, fileId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/${problemId}/events/${problemEventId}/files/${fileId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin. Проблемы.
   * @name DeleteComment1
   * @summary Удалить коментарий к проблеме
   * @request DELETE:/api/admin/problems/comments/{commentId}
   */
  deleteComment1 = (commentId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/problems/comments/${commentId}`,
      method: "DELETE",
      ...params,
    });
}
