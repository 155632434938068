const lsPrefix = 'aladmin_'

export const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function setToLS (key: string, value: any): void {
  localStorage.setItem(lsPrefix + key, JSON.stringify(value))
}

export function removeFromLS (key: string): void {
  localStorage.removeItem(lsPrefix + key)
}

export function getFromLS (key: string): any {
  const data = localStorage.getItem(lsPrefix + key)

  if (data != null) {
    return JSON.parse(data)
  }

  return null
}
