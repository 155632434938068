import React, { useCallback, useEffect } from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { DataGrid, type GridColDef, type GridPaginationModel, type GridRowsProp } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { mainApi } from '../../api'
import { type ProblemDto } from '../../api/mainApi'
import { toast } from 'react-toastify'
import ProblemActions from '../../components/Problem/ProblemActions'
import Button from '@mui/material/Button'

const baseGridCol: GridColDef = {
  field: '',
  editable: false,
  // minWidth: 150,
  flex: 1,
  filterable: false,
  sortable: false
}

interface ProblemTableRow {
  id: string
  name: string
  createdDate: string
  description: string
  cityName: string
  address: string
  status: string
  categoryName: string
  creationMethod: string
}
const columns: GridColDef[] = [
  { ...baseGridCol, field: 'id', headerName: 'ID', width: 50 },
  { ...baseGridCol, field: 'name', headerName: 'Назва' },
  { ...baseGridCol, field: 'createdDate', headerName: 'Дата створення' },
  { ...baseGridCol, field: 'description', headerName: 'Опис' },
  { ...baseGridCol, field: 'cityName', headerName: 'Місто' },
  { ...baseGridCol, field: 'address', headerName: 'Адреса' },
  { ...baseGridCol, field: 'status', headerName: 'Статус' },
  { ...baseGridCol, field: 'categoryName', headerName: 'Категорія' },
  { ...baseGridCol, field: 'creationMethod', headerName: 'Спосіб створення' },
  {
    ...baseGridCol,
    field: 'actions',
    headerName: 'Дії',
    renderCell: (props) => {
      const row = props.row as ProblemTableRow
      return (<ProblemActions
        status={row.status}
        problemId={Number.parseInt(row.id)}
        onActionCompleted={() => {
          // todo пока так
          window.location.reload()
        }}
      />)
    }
  }
]

const Problems: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [tableData, setTableData] = React.useState<GridRowsProp<ProblemTableRow>>([])
  const [rowCount, setRowCount] = React.useState<number>(0)
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 10
  })
  const navigate = useNavigate()

  const loadData = useCallback((page: number, size: number) => {
    setLoading(true)
    mainApi.admin.search3({
      page: page + 1,
      size
    }).then(r => {
      console.log('get problems response', r)
      setTableData(r.data?.data?.map((problem: ProblemDto) => {
        const createdDate = problem?.createdDate
          ? new Date(problem.createdDate).toLocaleDateString()
          : '-'
        const row: ProblemTableRow = {
          id: problem.id?.toString() ?? '',
          name: problem.name ?? '',
          createdDate,
          description: problem.description ?? '',
          cityName: problem.cityName ?? '',
          address: problem.address ?? '',
          status: problem.status ?? '',
          categoryName: problem.category?.nameUk ?? '',
          creationMethod: problem.creationMethod ?? ''
        }
        return row
      }) ?? [])
      setRowCount(r.data?.total ?? 0)
    }).catch(e => {
      console.log('get tenders error', e)
      toast('Помилка при завантаженні', { type: 'error' })
    }).finally(() => {
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    // loadData(1, 10)
  }, [])

  useEffect(() => {
    loadData(paginationModel.page, paginationModel.pageSize)
  }, [loadData, paginationModel])

  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h1>Проблеми </h1>
        <Button
          style={{ padding: 0, fontSize: 30 }}
          onClick={() => {
            navigate('/problem/new')
          }}> +</Button>

      </div>
      <DataGrid
        sx={{ overflowX: 'scroll' }}
        getRowHeight={() => 'auto'}
        density={'compact'}
        columns={columns}
        rows={tableData}
        rowCount={rowCount}
        isCellEditable={(params) => false}
        onCellClick={(cell, event) => {
          if (cell.field === 'actions') {
            event.stopPropagation()
          } else {
            console.log('cell click', cell)
            navigate(`/problem/${cell.id}`)
          }
        }}
        onRowClick={(params) => {
        }}
        editMode={'row'}
        loading={loading}
        pageSizeOptions={[10, 20, 50]}
        paginationMode={'server'}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode={'server'}
        onFilterModelChange={(e) => {
        }}
      />
    </div>
  )
}

export default observer(Problems)
