import React, { useCallback, useContext, useEffect } from 'react'
import { Divider, FormControl, InputLabel, LinearProgress, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import { problemApi } from '../../api'
import CategoryDropdown from './CategoryDropdown'
import Button from '@mui/material/Button'
import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import ProblemActions from './ProblemActions'
import { toast } from 'react-toastify'
import ProblemFileEditor from './ProblemFileEditor'
import GoogleMapReact from 'google-map-react'
import { AppConfig } from '../../AppConfig'
import { RootStoreContext } from '../../stores/rootStore'
import { type ProblemCreateDto, type ProblemExtendedDto } from '../../api/data-contracts'

export interface ProblemFormProps {
  problemId?: number
}

const DEFAULT_EMPTY_FORM: ProblemCreateDto = {
  name: '',
  description: '',
  cityName: undefined,
  address: '',
  lat: undefined,
  lon: undefined,
  requiredAmount: 0,
  categoryId: undefined,
  contactPhone: undefined
}

const Marker: React.FC<{ lat: number, lng: number, text: string }> =
    ({ lat, lng, text }) => {
      return (<div style={{
        width: 20,
        height: 20
      }}>
        <div style={{
          fontSize: 32,
          marginLeft: '-100%',
          marginTop: -20,
          lineHeight: 0
        }}>📍</div>
      </div>)
    }

const ProblemForm: React.FC<ProblemFormProps> = (props: ProblemFormProps) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loadedProblem, setLoadedProblem] = React.useState<ProblemExtendedDto | undefined>(undefined)
  const navigate = useNavigate()
  const [problemForm, setProblemForm] = React.useState<ProblemCreateDto>(DEFAULT_EMPTY_FORM)
  const { problemStore } = useContext(RootStoreContext)
  const loadProblem = useCallback(() => {
    if (!props.problemId) {
      setLoading(false)
      return
    }
    setLoading(true)
    problemApi.getOneProblem(props.problemId).then(r => {
      setLoadedProblem(r.data)
      console.log('loaded problem data', r.data)
      setProblemForm({
        name: r.data.name ?? '',
        description: r.data.description ?? '',
        cityName: r.data.cityName,
        address: r.data.address,
        lat: r.data.lat,
        lon: r.data.lon,
        requiredAmount: r.data.requiredAmount ?? 0,
        categoryId: r.data.category?.id,
        contactPhone: r.data.contactPhone
      })
    }).catch(e => {
      console.log('error loading problem details', e)
    }).finally(() => {
      setLoading(false)
    })
  }, [props.problemId])

  useEffect(() => {
    loadProblem()
  }, [loadProblem])

  useEffect(() => {
    console.log('init problem form', props.problemId)
    if (props.problemId) {
      return
    }
    console.log('init probblem form', problemStore.newProblemFormData)
    if (problemStore.newProblemFormData) {
      setProblemForm(problemStore.newProblemFormData)
      problemStore.setNewProblemFormData(null)
    }
  }, [problemStore, props.problemId])

  const createProblem = useCallback(() => {
    setLoading(true)
    problemApi.createProblem(problemForm)
      .then(r => {
        toast.success('Проблему створено')
        navigate(-1)
        navigate(`/problem/${r.data.id ?? 0}`)
      }).catch(e => {
        console.log('error creating problem', e)
        toast.error('Помилка при створенні проблеми')
      }).finally(() => {
        setLoading(false)
      })
  }, [navigate, problemForm])

  const updateProblem = useCallback(() => {
    console.log('updating problem', problemForm)
    if (!loadedProblem?.id) {
      return
    }
    setLoading(true)
    problemApi.updateProblem(loadedProblem.id, problemForm)
      .then(r => {
        toast.success('Проблему оновлено')
      }).catch(e => {
        console.log('error updating problem', e)
        toast.error('Помилка при оновленні проблеми')
      }).finally(() => {
        setLoading(false)
      })
  }, [loadedProblem?.id, problemForm])

  return (
    <Box >
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {
       !loading && !loadedProblem && (
       <h4>Створення нової проблеми</h4>
       )
      }
      {
       !loading && loadedProblem && (
       <div>
         <InputLabel>Проблема: </InputLabel>
         <b>{loadedProblem.id}</b>
       </div>
       )
       }
      <div className={styles.space}/>
      {loadedProblem?.status && <>
        <div>
          <InputLabel>Статус: </InputLabel>
          <b>{loadedProblem?.status}</b>
        </div>
        <br/>
        <ProblemActions
          problemId={loadedProblem.id}
          status={loadedProblem.status}
          onActionCompleted={loadProblem}
            />
        </>}

      <div className={styles.space}/>
      <TextField
        style={{}}
        fullWidth={true}
        label={'Коротка назва проблеми'}
        required={true}
        error={false}
        helperText={''}
        value={problemForm.name}
        onChange={(e) => {
          setProblemForm({ ...problemForm, name: e.target.value })
        }}
        />

      <div className={styles.space}/>
      <TextField
        style={{}}
        fullWidth={true}
        multiline={true}
        label={'Опис'}
        value={problemForm.description}
        onChange={(e) => {
          setProblemForm({ ...problemForm, description: e.target.value })
        }}
        rows={5}
        maxRows={5} />
      <div className={styles.space}/>
      <CategoryDropdown initialValue={loadedProblem?.category?.id}
        style={{}}
        onChange={(val) => { setProblemForm({ ...problemForm, categoryId: val }) }}
        />
      <div className={styles.space}/>
      <Divider style={{ marginTop: 35 }}/>
      <div className={styles.space}/>

      <TextField
        style={{}}
        fullWidth={true}
        label={'Місто'}
        value={problemForm.cityName ?? ''}
        onChange={(e) => {
          setProblemForm({ ...problemForm, cityName: e.target.value })
        }} />

      <div className={styles.space}/>
      <TextField
        style={{}}
        fullWidth={true}
        multiline={true}
        label={'Адреса'}
        value={problemForm.address}
        onChange={(e) => {
          setProblemForm({ ...problemForm, address: e.target.value })
        }}
        rows={3}
        maxRows={3} />

      <div className={styles.space}/>
      <FormControl >
        <TextField
          label={'lat'}
          type={'number'}
          value={problemForm.lat?.toString() ?? ''}
          onChange={(e) => {
            setProblemForm({ ...problemForm, lat: Number.parseFloat(e.target.value) })
          }}/>
        <div className={styles.space}/>
        <TextField
          label={'lon'}
          type={'number'}
          value={problemForm.lon?.toString() ?? ''}
          onChange={(e) => {
            setProblemForm({ ...problemForm, lon: Number.parseFloat(e.target.value) })
          }}/>
      </FormControl>
      <div className={styles.space}/>

      {!loading && (
        <div style={{ width: '100%', height: '30vh' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: AppConfig.GOOGLE_API_KEY }}
            defaultZoom={problemForm.lat ? 16 : 9}
            defaultCenter={{
              lat: problemForm.lat ?? 50.4501402,
              lng: problemForm.lon ?? 30.564994
            }}
            onClick={(e) => {
              setProblemForm({
                ...problemForm,
                lat: e.lat,
                lon: e.lng
              })
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              console.log('map loaded', map, maps)
            }}
                >
            {problemForm.lat && problemForm.lon && (
            <Marker
              lat={problemForm.lat}
              lng={problemForm.lon}
              text="Marker"
                        />
            )}
          </GoogleMapReact>
        </div>
      )}
      <div className={styles.space}/>

      <Divider style={{ marginTop: 35 }}/>

      <div className={styles.space}/>
      <TextField
        style={{}}
        type={'number'}
        fullWidth={true}
        label={'Необхідна сума'}
        required={true}
        error={false}
        helperText={''}
        value={problemForm.requiredAmount}
        onChange={(e) => {
          setProblemForm({ ...problemForm, requiredAmount: Number.parseInt(e.target.value) })
        }}
        />
      {
            loadedProblem && (
            <>
              <div className={styles.space}/>
              <ProblemFileEditor
                problemId={loadedProblem.id}
                problemFiles={loadedProblem.files ?? []}
              />
            </>
            )
        }
      <div className={styles.space}/>
      <div>
        <Button
          disabled={loading}
          variant={'contained'}
          color={'primary'}
          onClick={props.problemId ? updateProblem : createProblem}
        >
          Зберегти
        </Button>
      </div>
      <div className={styles.space}/>
    </Box>
  )
}

export default ProblemForm
