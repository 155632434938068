import React, { useContext } from 'react'
import UserImg from '../../assets/user.png'
import IconLogout from '../../assets/icons/logout.svg'
import styles from './styles.module.scss'
import { RootStoreContext } from '../../stores/rootStore'
import { observer } from 'mobx-react-lite'

const NavigationHeader: React.FC = () => {
  const { accountStore } = useContext(RootStoreContext)
  const isAuthenticated = accountStore.getIsAuthenticated
  const authData = accountStore.authData

  return (
    <div className={styles.header}>
      <div style={{ flex: 2 }}>
        {/*  <img style={{ height: 45, margin: 15 }} src={IconAlpha} alt="Alpha"/> */}
      </div>
      <div className={styles.rightSection}>
        {
          isAuthenticated
            ? (
              <div className={styles.avatarBlock}>
                <div className={styles.userWrapper}>
                  <div className={styles.userInfo}>
                    <span>
                      {
                        authData?.adminUser?.email
                      }
                    </span>
                    <span className={styles.userName}>
                      {
                        authData?.adminUser?.name
                      }
                    </span>
                  </div>
                  <img src={UserImg} alt="avatar" className={styles.userAvatarImg}/>
                </div>
                <button
                  className={styles.logoutBtn}
                  onClick={(e) => {
                    accountStore.logout()
                  }}>
                  <img src={IconLogout} alt={'Logout'}/>
                </button>
              </div>
              )
            : (
              <div></div>
              )
        }
      </div>
    </div>
  )
}

export default observer(NavigationHeader)
