import React, { useEffect, useState } from 'react'
import { type ProblemCategoryDto } from '../../api/mainApi'
import { problemApi } from '../../api'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'

export interface CategoryDropdownProps {
  style?: React.CSSProperties
  initialValue?: number
  onChange?: (val: number | undefined) => void
}

const CategoryDropdown: React.FC<CategoryDropdownProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [categories, setCategories] = useState<ProblemCategoryDto[]>([])
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1)
  const [error, setError] = useState<string>('')
  useEffect(() => {
    if (categories.length > 0) {
      return
    }
    setLoading(true)
    problemApi.searchCategories({ page: 1, size: 20 })
      .then(r => {
        setCategories(r.data.data ?? [])
      }).catch(e => {
        setError('Помилка при завантаженні')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [categories.length])

  useEffect(() => {
    if (props.initialValue) {
      const found = categories?.find(c => c.id === props.initialValue)
      if (found?.id) {
        setSelectedCategoryId(found.id)
      }
    } else {
      setSelectedCategoryId(-1)
    }
  }, [categories, props.initialValue])

  return (
    <FormControl fullWidth style={props.style}>
      <InputLabel>Категорія</InputLabel>
      <Select
        value={selectedCategoryId}
        error={!!error}
        label={`Категорія${loading ? ' (Завантаження...)' : ''}`}
        onChange={event => {
          if (event.target.value === '') {
            setSelectedCategoryId(-1)
            props.onChange?.(undefined)
          }
          const categoryId = typeof event.target.value === 'string'
            ? parseInt(event.target.value)
            : event.target.value
          setSelectedCategoryId(categoryId)
          props.onChange?.(categoryId)
        }}
      >
        <MenuItem value={-1}>
          {''}
        </MenuItem>
        {
          categories.map((el: any) => {
            return (
              <MenuItem key={el.id} value={el.id}>
                {el.nameUk}
              </MenuItem>
            )
          })
        }
      </Select>
      {error && (
      <FormHelperText error={true}>{error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default CategoryDropdown
