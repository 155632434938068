import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow,
  Card,
  CardContent,
  Link,
  Avatar,
  CardHeader,
  CardActions
} from '@mui/material'
import dayjs from 'dayjs'
import { red } from '@mui/material/colors'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Image from 'mui-image'
import Typography from '@mui/material/Typography'
import { mainApi } from '../../api'
import { type AdminPostDto, type PostComplainDto, type PostFileLightDto, type UserLightDto } from '../../api/mainApi'
import Button from '@mui/material/Button'
import TablePlaceholder from '../TablePlaceholder/TablePlaceholder'
import CancelIcon from '@mui/icons-material/Cancel'
import { toast } from 'react-toastify'

interface AdminPostDtoExtended extends AdminPostDto {
  complains?: PostComplainDto[]
  relatedUsers?: UserLightDto[]
  files?: PostFileLightDto[]
}

const POST_STATUSES = {
  ACTIVE: 'Активний',
  HIDDEN: 'Прихований',
  DELETED: 'Видалений'
}

const getLabel = (status: string | undefined): string => {
  if (status === 'ACTIVE' || status === 'HIDDEN' || status === 'DELETED') {
    return POST_STATUSES[status] || ''
  }

  return ''
}

const ComplainItem = (props: {
  complainAuthor: UserLightDto | undefined
  complain: PostComplainDto
  number: number
  onUpdate: () => void
}): JSX.Element => {
  const { complainAuthor, complain, number, onUpdate } = props
  const complainAuthorName = complainAuthor?.name ?? complainAuthor?.id ?? '-'

  return (
    <Card sx={{
      marginBottom: 2,
      opacity: complain.status === 'ACTIVE' ? 1 : 0.3,
      userSelect: complain.status === 'ACTIVE' ? undefined : 'none'
    }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            {complainAuthorName.charAt(0)}
          </Avatar>
              }
              // action={
              //     <IconButton aria-label="settings">
              //         <ShareIcon />
              //     </IconButton>
              // }
        title={`Скарга №${number} від: ${complainAuthorName}`}
        subheader={dayjs(complain.createdDate).format('DD.MM.YYYY HH:mm')}
          />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {complain.details}
        </Typography>
      </CardContent>
      {complain.status === 'ACTIVE'
        ? (
          <CardActions disableSpacing>
            <IconButton onClick={() => {
              const confirmed = confirm('Відхилити?')
              if (confirmed) {
                mainApi.admin.rejectComplain(complain.id ? complain.id : 0)
                  .then(({ data }) => {
                    if (data && data.status === 'REJECTED') {
                      toast.success('Відхилено')
                      if (onUpdate) {
                        onUpdate()
                      }
                    }
                  })
                  .catch((e) => {
                    console.log(e)
                  })
              }
            }} aria-label="decline">
              <CancelIcon sx={{ color: red[500] }} />
            </IconButton>
          </CardActions>
          )
        : (
          <CardActions disableSpacing>
            <div style={{
              paddingLeft: 10,
              color: complain.status === 'REJECTED' ? 'red' : 'green'
            }}>Статус скарги: {complain.status === 'REJECTED' ? 'відхилена' : 'схвалена'}</div>
          </CardActions>
          )}
    </Card>

  )
}

function Row (props: {
  row: AdminPostDtoExtended
  onUpdate: () => void
  onFirstDetailsOpen: () => void
  colsLength: number
}): JSX.Element {
  const { row, onUpdate, onFirstDetailsOpen, colsLength } = props
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const changePostStatus = useCallback((rowId: number | undefined, newStatus: 'ACTIVE' | 'HIDDEN' | 'DELETED'): void => {
    setLoading(true)
    mainApi.admin.updateStatus(rowId ?? 0, { newStatus })
      .then(() => {
        onUpdate()
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [onUpdate])

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
              if (!row.complains?.length && onFirstDetailsOpen) {
                onFirstDetailsOpen()
              }
            }}
                    >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.content ? row.content.substring(0, 50) : '' }
        </TableCell>
        <TableCell align="center">{row.complainsActiveCount}</TableCell>
        <TableCell align="center">{row.complainsTotalCount}</TableCell>
        <TableCell align="center">{row.createdByUserId}</TableCell>
        <TableCell align="right">{getLabel(row.status)}</TableCell>
        <TableCell align="right">
          {row.status !== 'DELETED' && (
          <Button variant="contained"
            fullWidth={true}
            sx={{ marginBottom: 1 }}
            onClick={() => { changePostStatus(row.id ?? 0, 'DELETED') }}
            disabled={loading}>Видалити</Button>
          )}
          {row.status !== 'HIDDEN' && (
          <Button variant="contained"
            sx={{ marginBottom: 1 }}
            fullWidth={true}
            onClick={() => { changePostStatus(row.id ?? 0, 'HIDDEN') }}
            disabled={loading}>Приховати</Button>
          )}
          {row.status !== 'ACTIVE' && (
          <Button variant="contained"
            fullWidth={true}
            onClick={() => { changePostStatus(row.id, 'ACTIVE') }}
            disabled={loading}>Відновити</Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colsLength}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="subtitle2" gutterBottom component="div">
                Повний текст посту
              </Typography>
              <Typography variant="body2" gutterBottom component="div">
                {row.content ? row.content : 'без тексту'}
              </Typography>
              {!!row.previewPhotoUrl && (
              <Box sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  Головне фото
                </Typography>
                <Image
                  src={row.previewPhotoUrl}
                  width="200px"
                  fit="cover"
                  duration={3000}
                  easing="cubic-bezier(0.7, 0, 0.6, 1)"
                  showLoading={ false }
                  errorIcon={ true }
                  shift={'top'}
                  distance="100px "
                  shiftDuration={900}
                  bgColor="inherit"
                                    />
              </Box>
              )}

              {!!row.files?.length && (
              <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Файли прикріплені до посту
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Тип</TableCell>
                      <TableCell>Посилання</TableCell>
                      <TableCell>Мініатюра</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.files?.map((file: PostFileLightDto, i: number) => (
                      <TableRow key={i}><TableCell component="th" scope="row">
                        {file.id}
                      </TableCell>
                        <TableCell>
                          {file.type}
                        </TableCell>
                        <TableCell>
                          <div style={{ maxWidth: 300, wordWrap: 'break-word' }}>
                            <Link href={file.url}>{file.url}</Link>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Image
                            src={file.url ?? ''}
                            width="100px"
                            fit="cover"
                            duration={3000}
                            easing="cubic-bezier(0.7, 0, 0.6, 1)"
                            showLoading={ false }
                            errorIcon={ true }
                            shift={'left'}
                            distance="100px "
                            shiftDuration={900}
                            bgColor="inherit"
                                                        />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              )}
              {Array.isArray(row.complains) && row.complains.length && (
              <>
                <Typography variant="h6" gutterBottom component="div">
                  Скарги на пост
                </Typography>
                {
                  row.complains?.map((complain: PostComplainDto, i: number) => {
                    const { relatedUsers } = row
                    const complainAuthor = relatedUsers?.find((relatedUser: UserLightDto) => {
                      return relatedUser.id === complain.createdByUserId
                    })
                    return <ComplainItem
                      key={i}
                      onUpdate={onUpdate}
                      number={i + 1}
                      complainAuthor={complainAuthor ?? undefined}
                      complain={complain}
                    />
                  })}
              </>
              )
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const ComplainsList = (): any => {
  const ref = useRef<HTMLTableSectionElement | null>(null)
  const COLUMNS = [
    '', 'Текст', 'Нових скарг', 'Всього скарг', 'ID юзера', 'Статус', 'Дія'
  ]
  const [items, setItems] = useState([] as AdminPostDtoExtended[])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [itemsOnPage, setItemsOnPage] = useState(1)
  const [tableBodyHeight, setTableBodyHeight] = useState(100)
  const [loading, setLoading] = useState(false)
  // const navigate = useNavigate()

  const updateCurrentTableBodyHeight = useCallback(() => {
    if (ref.current?.offsetHeight) {
      setTableBodyHeight(ref.current.offsetHeight)
    }
  }, [])

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage)
  }, [])

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setSize(parseInt(event.target.value, 10))
  }, [])

  const loadDetails = useCallback((id: number) => {
    mainApi.posts.getFiles(id)
      .then(({ data }) => {
        setItems(items.map((item) => {
          if (item.id === id) {
            item.files = data
          }
          return item
        }))
      })
      .catch((e) => {
        debugger
        console.error(e)
      })

    mainApi.admin.getComplainsPage(id, { page: 1, size: 10 })
      .then(({ data }) => {
        console.log(data)
        setItems(items.map((item) => {
          if (item.id === id) {
            item.relatedUsers = data.relatedUsers
            item.complains = data.data
          }
          return item
        }))
      })
      .catch((e) => {
        console.error(e)
      })
  }, [items])

  const loadPosts = useCallback(() => {
    setLoading(true)
    mainApi.admin.getPosts1({ page: page + 1, size })
      .then(({ data }) => {
        if (data.data) {
          setItems(data.data)
          setItemsOnPage(data.data.length)
          setTotal(data.total ?? 0)
        }
        updateCurrentTableBodyHeight()
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, size, updateCurrentTableBodyHeight])

  useEffect(() => {
    loadPosts()
  }, [loadPosts, page, size])

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {COLUMNS.map((title, i) => {
                return (<TableCell align={i === 1 ? 'left' : 'center'} key={i}>{title}</TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody
            ref={ref}>
            <TablePlaceholder items={items}
              itemsOnPage={itemsOnPage}
              tableBodyHeight={tableBodyHeight}
              loading={loading}
              colsLength={COLUMNS.length}></TablePlaceholder>
            {items.map((row) => (
              <Row key={row.id}
                row={row}
                colsLength={COLUMNS.length}
                onFirstDetailsOpen={() => {
                  loadDetails(row.id ?? 0)
                }}
                onUpdate={() => {
                  loadPosts()
                }} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={size}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </>
  )
}

export default observer(ComplainsList)
