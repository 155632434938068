import React, { useCallback, useEffect } from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { problemApi, tendersApi } from '../../api'
import { DataGrid, type GridColDef, type GridPaginationModel, type GridRowsProp } from '@mui/x-data-grid'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { type OpTenderDto, type ProblemDto, type TenderDto } from '../../api/data-contracts'

const baseGridCol: GridColDef = {
  field: '',
  editable: false,
  minWidth: 150,
  flex: 1,
  filterable: false,
  sortable: false,
  resizable: true
}

interface TableRow {
  id: string
  name: string
  problem: ProblemDto | null
  createdDate: string
  endDate: string
  price: number
  city: string
  ownerName: string
  tenderObject?: OpTenderDto
}

const TenderProblemPreview: React.FC<{ row: TableRow, problem: ProblemDto | null }> =
    ({ row, problem }) => {
      const [createNewLoading, setCreateNewLoading] = React.useState<boolean>(false)

      if (!problem) {
        return (<Button onClick={() => {
          if (createNewLoading) {
            return
          }
          setCreateNewLoading(true)
          tendersApi.prepareProblem(row.id).then(r => {
            console.log('prepared problem ', r)
            window.open('/problem/' + r.data.id.toString(), '_blank')
          }).catch(e => {
            toast('Помилка при створенні проблеми', { type: 'error' })
          }).finally(() => {
            setCreateNewLoading(false)
          })
        }}>Створити проблему ↗</Button>)
      }
      return (<div>
        <div>ID: {problem?.id}</div>
        <div>Назва: {problem?.name}</div>
        <Button onClick={() => {
          window.open(`/problem/${problem.id}`, '_blank')
        }}>Деталі ↗</Button>
      </div>)
    }

const columns: GridColDef[] = [
  { ...baseGridCol, field: 'id', headerName: 'ID' },
  { ...baseGridCol, field: 'name', headerName: 'Назва' },
  {
    ...baseGridCol,
    field: 'problemId',
    headerName: 'Проблема',
    renderCell: (props) => (
      <TenderProblemPreview
        problem={props.row.problem}
        row={props.row}/>)
  },
  { ...baseGridCol, field: 'createdDate', headerName: 'Дата створення' },
  { ...baseGridCol, field: 'endDate', headerName: 'Дата закінчення' },
  { ...baseGridCol, field: 'price', headerName: 'Вартість' },
  { ...baseGridCol, field: 'city', headerName: 'Місто' },
  { ...baseGridCol, field: 'ownerName', headerName: 'Замовник' }
]

const Tenders: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [tableData, setTableData] = React.useState<GridRowsProp<TableRow>>([])
  const [rowCount, setRowCount] = React.useState<number>(0)
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 10
  })
  const navigate = useNavigate()

  const loadData = useCallback((page: number, size: number) => {
    setLoading(true)
    tendersApi.getTenders({
      page: page + 1,
      size,
      withCreatedProblem: undefined,
      tenderAmountFrom: 10 ** 6
    }).then(tendersResponse => {
      console.log('get tenders response', tendersResponse)
      const relatedProblemIds = tendersResponse.data?.relatedProblems ?? [];
      (relatedProblemIds.length > 0
        ? problemApi.getProblemsByIds({ ids: relatedProblemIds })
        : Promise.resolve({ data: [] })
      ).then(relatedProblemDtos => {
        const newTableData = tendersResponse.data?.data?.map((tender: TenderDto) => {
          const { tenderData } = tender
          const createdDate = tenderData?.dateCreated
            ? new Date(tenderData?.dateCreated).toLocaleDateString()
            : '-'
          const endDate = tenderData?.tenderPeriod?.endDate
            ? new Date(tenderData?.tenderPeriod?.endDate).toLocaleDateString()
            : '-'
          const problem = relatedProblemDtos.data?.find(p => p.tenderId === tender.id) ?? null
          return {
            id: tender.id,
            name: tenderData?.title ?? '',
            problem,
            createdDate,
            endDate,
            price: tenderData?.value?.amount ?? 0,
            city: tenderData?.procuringEntity?.address?.locality ?? '',
            ownerName: tenderData?.procuringEntity?.name ?? '',
            tenderObject: tenderData
          }
        }) ?? []
        setTableData(newTableData)
        setRowCount(tendersResponse.data?.total ?? 0)
        setLoading(false)
      }).catch(e => {
        toast('Помилка при завантаженні повязаних проблем', { type: 'error' })
        setLoading(false)
      })
    }).catch(e => {
      console.log('get tenders error', e)
      toast('Помилка при завантаженні', { type: 'error' })
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    loadData(paginationModel.page, paginationModel.pageSize)
  }, [loadData, paginationModel])

  return (
    <div className={styles.wrapper}>
      <h1>Тендери</h1>
      <DataGrid
        sx={{ overflowX: 'scroll' }}
        getRowHeight={() => 'auto'}
        columns={columns}
        rows={tableData}
        rowCount={rowCount}
        isCellEditable={(params) => false}
        onCellClick={(params, event) => {
          if (params.field === 'problemId') {
            event.stopPropagation()
            return
          }
          navigate(`/tender/${params.id}`)
        }}
        editMode={'row'}
        loading={loading}
        pageSizeOptions={[10, 20, 50]}
        paginationMode={'server'}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode={'server'}
        onFilterModelChange={(e) => {
        }}
            />
    </div>
  )
}

export default observer(Tenders)
