import React, { useContext } from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import App from './layout/App'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ukUA } from '@mui/material/locale'
import ProtectedRoute from './components/ProtectedRoute'
import HomePage from './views/Home/HomePage'
import { rootStore, RootStoreContext } from './stores/rootStore'
import { observer } from 'mobx-react-lite'
import Login from './views/Login/Login'
import Complains from './views/Complains/Complains'
import Register from './views/Register/Register'
import Problems from './views/Problems/Problems'
import NotFoundPage from './views/NotFoundPage/NotFoundPage'
import Tenders from './views/Tenders/Tenders'
import TenderDetails from './views/Tenders/TenderDetails'
import ProblemDetails from './views/Problems/ProblemDetails'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const Content = observer(() => {
  const { accountStore } = useContext(RootStoreContext)
  const isAuthenticated = accountStore.getIsAuthenticated
  const theme = createTheme(
    ukUA
  )
  const privileges = accountStore.authData?.adminUser?.privileges ?? []

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<App/>}>
            <Route index={true} element={
              <HomePage/>
            }/>
            {privileges.includes('POSTS_COMPLAINS_READ') && (
            <Route path="/complain">
              <Route index={true} element={
                <ProtectedRoute isAllowed={isAuthenticated} redirectTo={'/login'}>
                  <Complains/>
                </ProtectedRoute>
                  }/>
              <Route path="/complain/:id" element={
                <ProtectedRoute isAllowed={isAuthenticated} redirectTo={'/login'}>
                  <div>Жалоба</div>
                </ProtectedRoute>
                  }/>
            </Route>
            )}
            {
              privileges.includes('PROBLEMS_READ') && (
              <Route path="/problem">
                <Route index={true} element={
                  <ProtectedRoute isAllowed={isAuthenticated} redirectTo={'/login'}>
                    <Problems/>
                  </ProtectedRoute>
                      }/>
                <Route path="/problem/:problemId" element={
                  <ProtectedRoute isAllowed={isAuthenticated} redirectTo={'/login'}>
                    <ProblemDetails/>
                  </ProtectedRoute>
                      }/>
                <Route path="/problem/new" element={
                  <ProtectedRoute isAllowed={isAuthenticated} redirectTo={'/login'}>
                    <ProblemDetails/>
                  </ProtectedRoute>
                }/>
              </Route>
              )
            }
            {
                privileges.includes('TENDERS_READ') && (
                <Route path="/tender">
                  <Route index={true} element={
                    <ProtectedRoute isAllowed={isAuthenticated} redirectTo={'/login'}>
                      <Tenders/>
                    </ProtectedRoute>
                      }/>
                  <Route path="/tender/:tenderId" element={
                    <ProtectedRoute isAllowed={isAuthenticated} redirectTo={'/login'}>
                      <TenderDetails/>
                    </ProtectedRoute>
                      }/>
                </Route>
                )
            }

            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/register'} element={<Register/>}/>

            <Route path={'*'} element={<NotFoundPage/>}/>
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  )
})

root.render(
  <RootStoreContext.Provider value={rootStore}>
    <StyledEngineProvider injectFirst>
      <Content/>
    </StyledEngineProvider>
  </RootStoreContext.Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
