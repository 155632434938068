import React, { type ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

export interface IProtectedRouteProps {
  isAllowed: boolean
  children: ReactElement
  redirectTo?: string
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = (props: IProtectedRouteProps) => {
  if (props.isAllowed) {
    return props.children
  } else {
    return <Navigate to={props.redirectTo ? props.redirectTo : '/'} replace={true}/>
  }
}

export default ProtectedRoute
