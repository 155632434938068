import React from 'react'
import styles from './styles.module.scss'
import Box from '@mui/material/Box'
import { Skeleton } from '@mui/material'

interface Props {
  items: any[]
  loading: boolean
  tableBodyHeight: number
  itemsOnPage: number
  colsLength: number
}

const TablePlaceholder = (props: Props): JSX.Element => {
  const rowsFakeArray = new Array(props.itemsOnPage).fill('')

  return (
    <>
      {!props.items.length && props.loading && (
      <tr style={{ height: props.tableBodyHeight }}>
        <td colSpan={props.colsLength}>
          <Box className={'table-loading-placeholder'}>
            {rowsFakeArray.map((item, i) => (
              <Skeleton style={{
                width: '100%',
                transform: 'scale(1, 0.95)'
              }} key={i} height={110} animation="wave"/>
            ))}
          </Box>
        </td>
      </tr>
      )}
      {!props.items.length && !props.loading && (
      <tr style={{ height: props.tableBodyHeight }}>
        <td colSpan={props.colsLength}>
          <Box className={'table-loading-placeholder'}>
            <p className={styles.emptyText}>Пусто</p>
          </Box>
        </td>
      </tr>
      )}
    </>
  )
}

export default TablePlaceholder
