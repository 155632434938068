import { createContext } from 'react'
import AccountStore from './accountStore'
import ProblemStore from './problemStore'

export class RootStore {
  accountStore: AccountStore
  problemStore: ProblemStore

  constructor () {
    this.accountStore = new AccountStore(this)
    this.problemStore = new ProblemStore()
  }
}

export const rootStore = new RootStore()

export const RootStoreContext = createContext<RootStore>(rootStore)
