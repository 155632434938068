import React, { useCallback } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import Box from '@mui/material/Box'
import { type ProblemFileDto } from '../../api/mainApi'
import Button from '@mui/material/Button'
import { problemApi } from '../../api'
import { LinearProgress } from '@mui/material'

export interface ProblemFileViewerProps {
  problemId: number
  problemFiles: ProblemFileDto[]
}

const ProblemFileEditor: React.FC<ProblemFileViewerProps> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [files, setFiles] = React.useState<ProblemFileDto[]>(props.problemFiles ?? [])

  const reloadFiles = useCallback(() => {
    problemApi.getProblemFiles(props.problemId).then(r => {
      console.log('loaded files', r.data)
      setFiles(r.data)
    }).catch(e => {
      console.log('error loading files', e)
    })
  }, [props.problemId])

  return (
    <Box>
      <FileUploader
        multiple={true}
        disabled={loading}
        handleChange={(files: FileList, event: any) => {
          console.log('files', files, 'event ', event)
          setLoading(true)
          Promise.all(Array.from(files).map(async file => {
            return await problemApi.uploadProblemFile(
              props.problemId,
              { type: 'IMAGE' },
              { file }
            )
          })).then(r => {
            console.log('uploaded files', r)
            reloadFiles()
          }).catch(e => {
            console.log('error uploading files', e)
          }).finally(() => {
            setLoading(false)
          })
        }}
        name="Name"
        label={'Виберіть зображення для завантаження до проблеми'}
        types={['JPEG', 'PNG', 'GIF']}
        />
      <div style={{ marginTop: 12 }}>
        {loading && (
          <LinearProgress hidden={!loading} />
        )}
        {/* <PhotoProvider> */}
        {/*  <div style={{ width: '100%', height: 200, marginTop: 16 }}> */}
        {/*    {props.problemFiles.map((item, index) => ( */}
        {/*      <PhotoView key={index} src={item.thumbnailUrl}> */}
        {/*        <img */}
        {/*          style={{ */}
        {/*            width: 150, */}
        {/*            objectFit: 'cover' */}
        {/*          }} */}
        {/*          src={item.thumbnailUrl} */}
        {/*          alt="" /> */}
        {/*      </PhotoView> */}
        {/*    ))} */}
        {/*  </div> */}
        {/* </PhotoProvider> */}
        {files.map((item, index) => (
          <div key={item.id}>
            <img
              style={{
                width: 150,
                objectFit: 'cover'
              }}
              src={item.thumbnailUrl}
              alt="" />
            <Button
              variant={'text'}
              disabled={loading}
              color={'error'}
              onClick={(): void => {
                if (!item.id) {
                  return
                }
                if (window.confirm('Ви дійсно бажаєте видалити це зображення?')) {
                  problemApi.deleteProblemFile(props.problemId, item.id).then(r => {
                    console.log('deleted file', r)
                    reloadFiles()
                  }).catch(e => {
                    console.log('error deleting file', e)
                  })
                }
              }}>
              Видалити
            </Button>

          </div>
        ))}
      </div>
    </Box>
  )
}

export default ProblemFileEditor
