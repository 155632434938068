import React, { useState } from 'react'
import { Container, Tab, Tabs } from '@mui/material'
import ProblemForm from '../../components/Problem/ProblemForm'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabPanel } from '@mui/lab'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const TABS = Object.freeze({
  DETAILS: '1',
  USERS: '2',
  RESOLVERS: '3',
  PAYMENTS: '4',
  EVENTS: '5'
})

const ProblemDetails: React.FC = () => {
  const [tabValue, setTabValue] = useState<string>(TABS.DETAILS)
  const { problemId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Container>
      <TabContext value={tabValue}>
        {problemId && (<Box>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => { setTabValue(newValue) }}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
              >
            {location.key && (
            <Button
              disabled={false}
              variant={'text'}
              onClick={() => { navigate(-1) }}
                >
              <span style={{ fontSize: 34, lineHeight: 0 }}>{'←'}</span>
            </Button>
            )}
            <Tab value={TABS.DETAILS} label="Деталізація"/>
            <Tab value={TABS.USERS} label="Користувачі" />
            <Tab value={TABS.RESOLVERS} label="Виконавці" />
            <Tab value={TABS.PAYMENTS} label="Платежі" />
            <Tab value={TABS.EVENTS} label="Журнал" />
          </Tabs>
        </Box>)
        }
        <TabPanel value={TABS.DETAILS} >
          <ProblemForm problemId={problemId ? Number.parseInt(problemId) : undefined} />
        </TabPanel>
      </TabContext>
    </Container>
  )
}

export default ProblemDetails
