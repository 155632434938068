import { action, makeObservable, observable } from 'mobx'
import { type ProblemCreateDto } from '../api/mainApi'
import { getFromLS, setToLS } from '../utils'

export default class ProblemStore {
  /**
   * When new problem page opened, this data will be used to fill the form
   */
  @observable newProblemFormData: ProblemCreateDto | null = null

  @action initialize = (): void => {
    const dataFromLs = getFromLS('newProblemFormData')
    console.log('dataFromLs', dataFromLs)
    if (dataFromLs) {
      this.newProblemFormData = dataFromLs
    }
  }

  @action setNewProblemFormData = (data: ProblemCreateDto | null): void => {
    console.log('setNewProblemFormData', data)
    setToLS('newProblemFormData', data)
    this.newProblemFormData = data
  }

  constructor () {
    this.initialize()
    makeObservable(this)
  }
}
