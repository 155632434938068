import React, { useEffect } from 'react'
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Popper } from '@mui/material'
import Button from '@mui/material/Button'
import { ArrowDropDown } from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import { problemApi } from '../../api'
import { toast } from 'react-toastify'

export interface ProblemActionsProps {
  problemId: number
  status: string
  onActionCompleted: (newStatus: string) => void
}

interface ButtonAction {
  show: boolean
  label: string
  color: string
  handler: () => void
}

function getActionsList (problemId: number, status: string, setStatus: (status: string) => void):
ButtonAction[] {
  return [
    {
      show: ['FUNDING', 'ARCHIVE'].includes(status),
      label: 'У процесі рішення',
      color: '',
      handler: (): void => {
        problemApi.setInProcess(problemId)
          .then(() => { setStatus('IN_PROCESS') })
          .catch(() => toast.error('Помилка при зміні статусу'))
      }
    },
    {
      show: ['FUNDING', 'IN_PROCESS'].includes(status),
      label: 'Архівувати',
      color: '',
      handler: (): void => {
        problemApi.archiveProblem(problemId)
          .then(() => { setStatus('ARCHIVE') })
          .catch(() => toast.error('Помилка при зміні статусу'))
      }
    },
    {
      show: ['PRE_MODERATION'].includes(status),
      label: 'Схвалити',
      color: '',
      handler: (): void => {
        problemApi.approveProblem(problemId)
          .then(() => { setStatus('FUNDING') })
          .catch(() => toast.error('Помилка при зміні статусу'))
      }
    },
    {
      show: !['REJECTED', 'ARCHIVE'].includes(status),
      label: 'Відхилити',
      color: '',
      handler: (): void => {
        problemApi.rejectProblem(problemId)
          .then(() => { setStatus('REJECTED') })
          .catch(() => toast.error('Помилка при зміні статусу'))
      }
    },
    {
      show: ['DRAFT'].includes(status),
      label: 'Попередня модерація',
      color: '',
      handler: (): void => {
        problemApi.sendToModeration(problemId)
          .then(() => { setStatus('PRE_MODERATION') })
          .catch(() => toast.error('Помилка при зміні статусу'))
      }
    }
  ].filter(a => a.show)
}

const ProblemActions: React.FC<ProblemActionsProps> = (props) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  // const [selectedIndex, setSelectedIndex] = React.useState(1)
  const [actions, setActions] = React.useState<ButtonAction[]>([])

  useEffect(() => {
    setActions(getActionsList(
      props.problemId,
      props.status,
      props?.onActionCompleted ?? ((s: string) => {})
    ))
  }, [props?.onActionCompleted, props.problemId, props.status])

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event): void => {
    if (
      anchorRef?.current?.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  return (<React.Fragment>
    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
      <Button
        size="small"
        disabled={actions.length === 0}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        Дії
        <ArrowDropDown />
      </Button>
    </ButtonGroup>
    <Popper
      sx={{
        zIndex: 2
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
          }}
          >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {actions.map((option, index) => (
                  <MenuItem
                    key={option.label}
                    disabled={!option.show}
                    onClick={(event) => {
                      event.stopPropagation()
                      handleToggle()
                      option.handler()
                    }}
                      >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </React.Fragment>)
}

export default ProblemActions
