import React from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import ComplainsList from '../../components/ComplainsList/ComplainsList'

const Complains: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Скарги на дописи</h1>
      <ComplainsList/>
    </div>
  )
}

export default observer(Complains)
