import React, { useCallback, useEffect } from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { mainApi } from '../../api'
import { type TenderDto } from '../../api/mainApi'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { JsonView, defaultStyles } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'
import Box from '@mui/material/Box'
import { LinearProgress } from '@mui/material'

const TenderDetails: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const { tenderId } = useParams()
  const [tender, setTender] = React.useState<TenderDto | null>(null)

  const loadData = useCallback(() => {
    setLoading(true)
    if (!tenderId || (tender != null && tender.id === tenderId)) {
      return
    }
    mainApi.admin.getTender(tenderId).then(r => {
      console.log('get tender details', r)
      setTender(r.data)
    }).catch(e => {
      console.log('get tenders error', e)
      toast('Помилка при завантаженні', { type: 'error' })
    }).finally(() => {
      setLoading(false)
    })
  }, [tender, tenderId])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <div className={styles.wrapper}>
      <h1>Тендер {tenderId}</h1>
      {loading && (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
      )}
      <JsonView data={tender ?? {}}
        shouldInitiallyExpand={(level) => true}
        style={defaultStyles} />
    </div>
  )
}

export default observer(TenderDetails)
