import { type RootStore } from './rootStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { getFromLS, removeFromLS, setToLS } from '../utils'
import { type AdminTokenResponseDto } from '../api/mainApi'

export default class AccountStore {
  rootStore: RootStore

  @observable authToken: string = ''
  @observable authData: AdminTokenResponseDto | null = null

  @computed get getIsAuthenticated (): boolean {
    return !!(this.authData?.refreshToken)
  };

  @action initialize = (): void => {
    const authData = getFromLS('authData')
    console.log('auth data', authData)
    if (authData?.accessToken) {
      this.authToken = authData.accessToken
      this.authData = authData
    } else {
      console.log('no token...')
    }
  }

  @action logout = (): void => {
    console.log('logout user')
    this.authToken = ''
    this.authData = null
    removeFromLS('authData')
  }

  @action setAuthData = (data: any): void => {
    if (data?.accessToken) {
      this.authToken = data.accessToken
      this.authData = data
      setToLS('authData', data)
    }
  }

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.initialize()

    makeObservable(this)
  }
}
