import React, { useCallback, useContext, useState } from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../stores/rootStore'
import { useNavigate } from 'react-router-dom'
import { mainApi } from '../../api'
import { toast } from 'react-toastify'
import Typography from '@mui/material/Typography'
import { Grid, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const Register: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const { accountStore } = useContext(RootStoreContext)
  const navigate = useNavigate()

  const loginUser = useCallback(() => {
    if (password !== passwordConfirm) {
      toast('Помилка - паролі не співпадають', { type: 'warning' })
      return
    }
    setLoading(true)
    mainApi.admin.registerEmail1({ email, password })
      .then(({ data }) => {
        accountStore.setAuthData(data)
        setTimeout(() => {
          navigate('/')
        }, 100)
      })
      .catch((e: any) => {
        console.log(e)
        toast('Помилка', { type: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [email, accountStore, navigate, password, passwordConfirm])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={0} md={3}/>
        <Grid item xs={12} md={6}>
          <Box className={styles.wrapper}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Реєстрація
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1, mb: 1 }}>
              Введіть пошту та пароль
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <TextField
                  value={email}
                  onChange={(val) => { setEmail(val.target.value) }}
                  required
                  fullWidth={true}
                  id="outlined-required"
                  label="Email"
                                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField required fullWidth={true}
                  onChange={(val) => { setPassword(val.target.value) }}
                  value={password}
                  type={'password'}
                  id="outlined-required"
                  label="Пароль"
                                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField required
                  fullWidth={true}
                  onChange={(val) => { setPasswordConfirm(val.target.value) }}
                  value={passwordConfirm}
                  type={'password'}
                  id="outlined-required"
                  label="Підтвердження паролю"
                                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button variant="text"
                  fullWidth={true}
                  onClick={() => {
                    setEmail('')
                    setPassword('')
                  }}
                                >Очистити</Button>
              </Grid>
              <Grid
                item sm={8} xs={12}>
                <Button variant="contained"
                  fullWidth={true}
                  onClick={loginUser}
                  disabled={loading || !email || !passwordConfirm || !passwordConfirm}>Зареєструватися</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={0} md={2}/>
      </Grid>
    </>
  )
}

export default observer(Register)
