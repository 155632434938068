import React, { useContext } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import Header from '../components/NavigationHeader/NavigationHeader'
import styles from './styles.module.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
// import IcoFolder from '../assets/icons/menu/folder.svg'
import IcoForm from '../assets/icons/menu/form.svg'
// import IcoPaper from '../assets/icons/menu/paper.svg'
import { RootStoreContext } from '../stores/rootStore'
import { observer } from 'mobx-react-lite'

interface MenuItemProps {
  path: string
  title: string
  imgSrc: string
}

// todo collapsible menu item

const MenuItem: React.FunctionComponent<MenuItemProps> = ({ path, title, imgSrc }) => {
  return (<NavLink className={styles.menuLink} to={path}>
    <div>
      <img src={imgSrc} alt="list"
        title={title}/>
      <span>{title}</span>
    </div>
  </NavLink>)
}

function App (): React.ReactElement {
  const { accountStore } = useContext(RootStoreContext)
  const isAuthenticated = accountStore.getIsAuthenticated
  const privileges = accountStore.authData?.adminUser?.privileges ?? []
  return (
    <div className={`App${isAuthenticated ? ' logged-in' : ''}`}>
      <Header/>
      <div className={styles.container}>
        <div className={styles.menu}>
          {
              privileges.includes('POSTS_COMPLAINS_READ') && (
              <div>
                <MenuItem imgSrc={IcoForm} path={'/complain'} title={'Скарги'}/>
              </div>
              )
          }
          {
              privileges.includes('PROBLEMS_READ') && (
              <div>
                <MenuItem imgSrc={IcoForm} path={'/problem'} title={'Проблеми'}/>
              </div>
              )
          }
          {
              privileges.includes('TENDERS_READ') && (
              <div>
                <MenuItem imgSrc={IcoForm} path={'/tender'} title={'Тендери'}/>
              </div>
              )
          }
        </div>
        <div className="page-container">
          <Outlet/>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover/>
    </div>

  )
}

export default observer(App)
