/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {AppConfig} from "../AppConfig";
import {RootStoreContext} from "../stores/rootStore";
import {toast} from "react-toastify";

export interface ProblemCreateDto {
  name: string;
  description: string;
  cityKoatuu?: string;
  cityName?: string;
  address?: string;
  /** @format double */
  lat?: number;
  /** @format double */
  lon?: number;
  requiredAmount?: number;
  /** @format int32 */
  categoryId?: number;
  contactPhone?: string;
}

export interface ProblemCategoryDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  nameUk?: string;
  nameRu?: string;
  deleted?: boolean;
  color?: string;
  additionalDescriptionUk?: string;
  additionalDescriptionRu?: string;
}

export interface ProblemDto {
  /** @format int32 */
  id: number;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  modifiedDate?: string;
  name?: string;
  description?: string;
  cityKoatuu?: string;
  cityName?: string;
  address?: string;
  /** @format double */
  lat?: number;
  /** @format double */
  lon?: number;
  requiredAmount?: number;
  ownerFullName?: string;
  ownerUserId?: string;
  owner?: ProblemUserDto;
  status?: "PRE_DRAFT" | "DRAFT" | "PRE_MODERATION" | "REJECTED" | "FUNDING" | "IN_PROCESS" | "ARCHIVE" | "DELETED";
  category?: ProblemCategoryDto;
  creationMethod?: "APP" | "CRM" | "LANDING";
  /** @format int32 */
  filesCount?: number;
  files?: ProblemFileDto[];
  previewPhotoUrl?: string;
  /** @format int32 */
  commentsCount?: number;
  /** @format int32 */
  usersCount?: number;
  shareLink?: string;
  storeLink?: string;
  withdrawalRequested?: boolean;
  /** @format int32 */
  viewsCount?: number;
  contactPhone?: string;
  canResolve?: boolean;
}

export interface ProblemFileDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  /** @format int32 */
  problemId?: number;
  /** @format int32 */
  problemEventId?: number;
  uploadedByUserId?: string;
  type?: "IMAGE" | "VIDEO" | "FILE";
  originalFilename?: string;
  url?: string;
  thumbnailUrl?: string;
  contentType?: string;
}

export interface ProblemUserDto {
  userId?: string;
  /** @format date-time */
  createdDate?: string;
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface UserEditDto {
  name?: string;
  about?: string;
}

export interface UserDto {
  id?: string;
  /** @format date-time */
  createdDate?: string;
  status?: "DRAFT" | "NOT_ACTIVATED" | "ACTIVE" | "DISABLED" | "DELETED";
  photo?: string;
  name?: string;
  about?: string;
  email?: string;
  emailVerified?: boolean;
  phone?: string;
}

export interface LocationDto {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface PostCreateDto {
  title?: string;
  content?: string;
  location?: LocationDto;
  /** @format int32 */
  relatedProblemId?: number;
}

export interface PostDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  createdByUserPhoto?: string;
  status?: "ACTIVE" | "HIDDEN" | "DELETED";
  title?: string;
  content?: string;
  previewPhotoUrl?: string;
  /** @format int32 */
  previewPhotoWidth?: number;
  /** @format int32 */
  previewPhotoHeight?: number;
  /** @format int32 */
  viewsCount?: number;
  /** @format int32 */
  commentsCount?: number;
  storeLink?: string;
  /** @format int32 */
  relatedProblemId?: number;
  /** @format int32 */
  likesCount?: number;
  likedByMe?: boolean;
  saved?: boolean;
}

export interface ProblemUserCreateDto {
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface ProblemEventCreateDto {
  name: string;
  description?: string;
  isPublic?: boolean;
  /** @format date-time */
  date?: string;
  city?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface ProblemEventDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  /** @format int32 */
  problemId?: number;
  problemName?: string;
  problemPreviewPhotoUrl?: string;
  name?: string;
  description?: string;
  /** @format date-time */
  date?: string;
  city?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format int32 */
  filesCount?: number;
  files?: ProblemFileDto[];
  public?: boolean;
}

export interface ProblemSurveyUpdateDto {
  questionUk?: string;
  questionRu?: string;
}

export interface ProblemSurveyDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  questionUk?: string;
  questionRu?: string;
  status?: "ACTIVE" | "DISABLED" | "DELETED";
  options?: ProblemSurveyOptionDto[];
}

export interface ProblemSurveyOptionDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  surveyId?: number;
  nameUk?: string;
  nameRu?: string;
}

export interface ProblemSurveyOptionCreateDto {
  nameUk: string;
  nameRu: string;
}

export interface ProblemCategoryCreateDto {
  nameUk: string;
  nameRu: string;
  color?: string;
  additionalDescriptionUk?: string;
  additionalDescriptionRu?: string;
}

export interface WayForPayCallbackResponse {
  orderReference?: string;
  status?: string;
  /** @format int64 */
  time?: number;
  signature?: string;
}

export interface ProblemShareResponseDto {
  shareLink?: string;
}

export interface ProblemPaymentCreateDto {
  amount: number;
}

export interface ProblemPaymentDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  /** @format int32 */
  problemId?: number;
  transactionId?: string;
  transactionPaymentStatus?: "CREATED" | "DECLINED" | "PENDING" | "SUCCESS" | "TIMEOUT";
  amount?: number;
  userId?: string;
  userName?: string;
  userAvatar?: string;
}

export interface ProblemEventAppCreateDto {
  name?: string;
  description?: string;
  /** @format date-time */
  date?: string;
  city?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface ProblemCommentCreateDto {
  /** @format int32 */
  parentCommentId?: number;
  content?: string;
}

export interface ProblemCommentDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  authorUserId?: string;
  authorUserName?: string;
  authorUserPhoto?: string;
  content?: string;
  /** @format int32 */
  childCount?: number;
}

export interface ProblemSurveyAnswerDto {
  /** @format int64 */
  surveyId?: number;
  /** @format int64 */
  optionId?: number;
  /** @format date-time */
  createdDate?: string;
  userId?: string;
}

export interface ProblemSurveyExtendedDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  questionUk?: string;
  questionRu?: string;
  status?: "ACTIVE" | "DISABLED" | "DELETED";
  options?: ProblemSurveyOptionDto[];
  myAnswer?: ProblemSurveyAnswerDto;
  results?: ProblemSurveyResultItem[];
}

export interface ProblemSurveyResultItem {
  /** @format int64 */
  optionId?: number;
  optionNameUk?: string;
  optionNameRu?: string;
  /** @format int32 */
  answeredTimes?: number;
  /** @format double */
  percent?: number;
}

export interface PostFileLightDto {
  id?: string;
  url?: string;
  type?: "IMAGE" | "VIDEO" | "PDF" | "OTHER";
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
}

export interface ComplainCreateDto {
  details?: string;
}

export interface PostCommentCreateDto {
  /** @format int32 */
  parentCommentId?: number;
  content?: string;
}

export interface PostCommentDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  status?: "ACTIVE" | "DELETED";
  /** @format int32 */
  postId?: number;
  /** @format int32 */
  parentCommentId?: number;
  authorUserId?: string;
  authorUserName?: string;
  authorUserPhoto?: string;
  content?: string;
  edited?: boolean;
  /** @format int32 */
  childCount?: number;
}

export interface PostViewRequestDto {
  postIds?: number[];
}

export interface PostPreferenceCreateRequestDto {
  /** @format int32 */
  ignoredPostId?: number;
  ignoredUserId?: string;
}

export interface PostPreferenceDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  /** @format int32 */
  ignoredPostId?: number;
  ignoredPostTitle?: string;
  ignoredUserId?: string;
  ignoredUserName?: string;
}

export interface RefreshTokenRequestDto {
  refreshToken?: string;
}

export interface TokenResponseDto {
  accessToken?: string;
  refreshToken?: string;
}

export interface EmailRegisterRequestDto {
  email?: string;
  name?: string;
  password?: string;
  tags?: string[];
}

export interface UserTokenResponseDto {
  accessToken?: string;
  refreshToken?: string;
  user?: UserDto;
}

export interface FirebaseLoginRequestDto {
  idToken?: string;
  tags?: string[];
}

export interface UserEmailLoginRequestDto {
  email: string;
  password: string;
}

export interface Address {
  streetAddress?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  countryName?: string;
}

export interface AuctionPeriod {
  shouldStartAfter?: string;
  startDate?: string;
}

export interface Classification {
  description?: string;
  scheme?: string;
  id?: string;
}

export interface ComplaintPeriod {
  startDate?: string;
  endDate?: string;
}

export interface ContactPoint {
  email?: string;
  telephone?: string;
  name?: string;
}

export interface Criteria {
  title?: string;
  description?: string;
  source?: string;
  relatesTo?: string;
  classification?: CriteriaClassification;
  id?: string;
  legislation?: Legislation[];
  requirementGroups?: RequirementGroup[];
}

export interface CriteriaClassification {
  scheme?: string;
  id?: string;
}

export interface DeliveryDate {
  endDate?: string;
}

export interface Document {
  documentType?: string;
  hash?: string;
  title?: string;
  format?: string;
  url?: string;
  documentOf?: string;
  id?: string;
  datePublished?: string;
  dateModified?: string;
  author?: string;
}

export interface EnquiryPeriod {
  startDate?: string;
  endDate?: string;
  clarificationsUntil?: string;
  invalidationDate?: string;
}

export interface Item {
  id?: string;
  description?: string;
  classification?: Classification;
  additionalClassifications?: Classification[];
  unit?: Unit;
  quantity?: string;
  relatedLot?: string;
  deliveryDate?: DeliveryDate;
  deliveryAddress?: Address;
}

export interface Legislation {
  version?: string;
  identifier?: LegislationIdentifier;
  type?: string;
  article?: string;
}

export interface LegislationIdentifier {
  id?: string;
  legalName?: string;
  uri?: string;
}

export interface Lot {
  title?: string;
  status?: string;
  id?: string;
  date?: string;
  value?: Value;
  minimalStep?: Value;
  auctionPeriod?: AuctionPeriod;
}

export interface Milestone {
  id?: string;
  title?: string;
  type?: string;
  code?: string;
  percentage?: string;
  duration?: MilestoneDuration;
  sequenceNumber?: string;
  relatedLot?: string;
}

export interface MilestoneDuration {
  days?: string;
  type?: string;
}

export interface OpTenderDto {
  plans?: PlanId[];
  criteria?: Criteria[];
  date?: string;
  dateModified?: string;
  /** @format date-time */
  dateCreated?: Date;
  tenderID?: string;
  documents?: Document[];
  owner?: string;
  title?: string;
  mainProcurementCategory?: string;
  submissionMethod?: string;
  procurementMethod?: string;
  value?: Value;
  minimalStep?: Value;
  lots?: Lot[];
  milestones?: Milestone[];
  procuringEntity?: ProcuringEntity;
  status?: string;
  procurementMethodType?: string;
  awardCriteria?: string;
  enquiryPeriod?: EnquiryPeriod;
  tenderPeriod?: TenderPeriod;
  items?: Item[];
  complaintPeriod?: ComplaintPeriod;
  next_check?: string;
  id?: string;
}

export interface PlanId {
  id?: string;
}

export interface ProcuringEntity {
  name?: string;
  name_en?: string;
  identifier?: ProcuringIdentifier;
  address?: Address;
  contactPoint?: ContactPoint;
  kind?: string;
}

export interface ProcuringIdentifier {
  scheme?: string;
  id?: string;
  legalName?: string;
  legalName_en?: string;
}

export interface Requirement {
  title?: string;
  dataType?: string;
  expectedValue?: string;
  status?: string;
  id?: string;
  datePublished?: string;
}

export interface RequirementGroup {
  description?: string;
  requirements?: Requirement[];
  id?: string;
}

export interface TenderDto {
  id: string;
  /** @format int32 */
  problemId: number | null;
  tenderData?: OpTenderDto;
}

export interface TenderPeriod {
  startDate?: Date
  endDate?: Date;
}

export interface Unit {
  name?: string;
  code?: string;
}

export interface Value {
  /** @format double */
  amount?: number;
  currency?: string;
  valueAddedTaxIncluded?: boolean;
}

export interface ProblemResolverChangeStatusDto {
  comment?: string;
}

export interface ProblemResolverDto {
  /** @format int32 */
  problemId?: number;
  userId?: string;
  userName?: string;
  userPhone?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  modifiedDate?: string;
  status?: "CREATED" | "REJECTED" | "APPROVED";
  comment?: string;
}

export interface ProblemSurveyCreateDto {
  questionUk: string;
  questionRu: string;
  options: ProblemSurveyOptionCreateDto[];
}

export interface PostComplainDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  /** @format int32 */
  postId?: number;
  /** @format int32 */
  postCommentId?: number;
  details?: string;
  createdByUserId?: string;
  status?: "ACTIVE" | "REJECTED" | "APPROVED";
}

export interface AdminTokenResponseDto {
  accessToken?: string;
  refreshToken?: string;
  adminUser?: AdminUserDto;
}

export type Privilege = (
    | "POSTS_READ"
    | "POSTS_UPDATE_STATUS"
    | "POSTS_COMPLAINS_READ"
    | "POSTS_COMPLAINS_REJECT"
    | "TENDERS_READ"
    | "PROBLEMS_CATEGORIES_READ"
    | "PROBLEMS_CATEGORIES_WRITE"
    | "PROBLEMS_READ"
    | "PROBLEMS_CREATE"
    | "PROBLEMS_VERIFY"
    | "PROBLEMS_SURVEYS_READ"
    | "PROBLEMS_SURVEYS_WRITE"
    | "PROBLEMS_USERS_READ"
    | "PROBLEMS_USERS_ADD"
    | "PROBLEMS_USERS_DELETE"
    | "PROBLEMS_PAYMENTS_READ"
    | "PROBLEMS_EVENTS_READ"
    | "PROBLEMS_EVENTS_WRITE"
    );

export interface AdminUserDto {
  id?: string;
  /** @format date-time */
  createdDate?: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  privilegeGroupId?: string;
  privileges?: Privilege[];
}

export interface AdminLoginRequestDto {
  email?: string;
  /**
   * @minLength 5
   * @maxLength 16
   */
  password?: string;
}

export interface PageDtoProblemUserExtendedDto {
  data?: ProblemUserExtendedDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface ProblemUserExtendedDto {
  userId?: string;
  /** @format date-time */
  createdDate?: string;
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  investedAmount?: number;
}

export interface PageDtoProblemPaymentDto {
  data?: ProblemPaymentDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PageDtoProblemCommentDto {
  data?: ProblemCommentDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PageDtoProblemDto {
  data?: ProblemDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface ProblemTotalReportDto {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  resolvedCount?: number;
}

export interface ProblemPaymentInfoDto {
  /** @format int64 */
  id?: number;
  amount?: number;
  /** @format int32 */
  problemId?: number;
  problemCategoryId?: string;
  problemCategoryName?: string;
  problemPreviewUrl?: string;
  problemName?: string;
  userName?: string;
  userAvatar?: string;
}

export interface ProblemNotificationsSummaryDto {
  /** @format int32 */
  totalCount?: number;
  /** @format int32 */
  unseenCount?: number;
}

export interface PageDtoProblemNotificationDto {
  data?: ProblemNotificationDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface ProblemNotificationDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  userId?: string;
  description?: string;
  type?: "PROBLEM_NEW_EVENT" | "PROBLEM_NEW_COMMENT" | "COMMENT_ANSWER";
  /** @format int32 */
  problemId?: number;
  seen?: boolean;
  /** @format date-time */
  seenDate?: string;
}

export interface ProblemExtendedDto {
  /** @format int32 */
  id: number;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  modifiedDate?: string;
  name?: string;
  description?: string;
  cityKoatuu?: string;
  cityName?: string;
  address?: string;
  /** @format double */
  lat?: number;
  /** @format double */
  lon?: number;
  requiredAmount?: number;
  ownerFullName?: string;
  ownerUserId?: string;
  owner?: ProblemUserDto;
  status?: "PRE_DRAFT" | "DRAFT" | "PRE_MODERATION" | "REJECTED" | "FUNDING" | "IN_PROCESS" | "ARCHIVE" | "DELETED";
  category?: ProblemCategoryDto;
  creationMethod?: "APP" | "CRM" | "LANDING";
  /** @format int32 */
  filesCount?: number;
  files?: ProblemFileDto[];
  previewPhotoUrl?: string;
  /** @format int32 */
  commentsCount?: number;
  /** @format int32 */
  usersCount?: number;
  shareLink?: string;
  storeLink?: string;
  withdrawalRequested?: boolean;
  /** @format int32 */
  viewsCount?: number;
  contactPhone?: string;
  canResolve?: boolean;
  currentUserInfo?: ProblemUserDto;
}

export interface ProblemAppConfigDto {
  binancePayEnabled?: boolean;
  donateButtonEnabled?: boolean;
}

export interface PageDtoProblemLightDto {
  data?: ProblemLightDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface ProblemLightDto {
  /** @format int32 */
  id?: number;
  name?: string;
  /** @format double */
  lat?: number;
  /** @format double */
  lon?: number;
  categoryId?: string;
  categoryName?: string;
  categoryColor?: string;
  previewPhotoUrl?: string;
  /** @format int32 */
  commentsCount?: number;
  /** @format int32 */
  usersCount?: number;
  shareLink?: string;
  /** @format int32 */
  viewsCount?: number;
  supportedByCurrentUser?: boolean;
}

export interface UserLightDto {
  id?: string;
  name?: string;
  about?: string;
}

export interface PostSearchRequestParamsDto {
  /**
   * @format int32
   * @min 1
   */
  page?: number;
  /**
   * @format int32
   * @min 1
   */
  size?: number;
  authorUserId?: string;
  tags?: string[];
  /** @format int32 */
  relatedProblemId?: number;
}

export interface PageDtoPostDto {
  data?: PostDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PageDtoPostCommentDto {
  data?: PostCommentDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface SavedPostSearchRequestParamsDto {
  /**
   * @format int32
   * @min 1
   */
  page?: number;
  /**
   * @format int32
   * @min 1
   */
  size?: number;
  tags?: string[];
}

export interface PageDtoPostPreferenceDto {
  data?: PostPreferenceDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface NotificationDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  seen?: boolean;
  /** @format int32 */
  postId?: number;
  /** @format int32 */
  postCommentId?: number;
  type?: "POST_COMMENT" | "COMMENT_ANSWER" | "PROBLEM_EVENT" | "PROBLEM_POST";
  postTitle?: string;
  postPreviewPhotoUrl?: string;
}

export interface NotificationsInfoDto {
  /** @format int32 */
  unseenCount?: number;
}

export interface TenderSearchRequestDto {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
  withCreatedProblem?: boolean;
}

export interface PageDtoTenderDto {
  data?: TenderDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PageRequestDto {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
}

export interface PageDtoProblemUserDto {
  data?: ProblemUserDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PageDtoProblemResolverDto {
  data?: ProblemResolverDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PageDtoProblemSurveyExtendedDto {
  data?: ProblemSurveyExtendedDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PageDtoProblemCategoryDto {
  data?: ProblemCategoryDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface AdminPostSearchParamsDto {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
}

export interface AdminPostDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  modifiedDate?: string;
  creationMethod?: "APP" | "CRM";
  createdByUserId?: string;
  status?: "ACTIVE" | "HIDDEN" | "DELETED";
  title?: string;
  content?: string;
  previewPhotoUrl?: string;
  location?: Location;
  /** @format int32 */
  viewsCount?: number;
  /** @format int32 */
  commentsCount?: number;
  storeLink?: string;
  /** @format int32 */
  complainsActiveCount?: number;
  /** @format int32 */
  complainsTotalCount?: number;
}

export interface Location {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface PageDtoAdminPostDto {
  data?: AdminPostDto[];
  /** @format int64 */
  total?: number;
  /**
   * @deprecated
   * @format int64
   */
  totalElements?: number;
}

export interface PostComplainResponseDto {
  data?: PostComplainDto[];
  /** @format int64 */
  total?: number;
  relatedUsers?: UserLightDto[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;

  secondAttempt?: boolean;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = AppConfig.CORE_API_URL;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "include",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // @ts-ignore
    const token = RootStoreContext._currentValue.accountStore.authToken;
    if (token) {
      // @ts-ignore
      requestParams.headers.Authorization = `Bearer ${token || ''}`;
    }

    return this.customFetch(`${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      let data;
      if (responseFormat) {
        data = await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e
              return r
            })
      } else {
        try {
          r.data = await r.json()
        } catch (e) {
          r.error = e as E
        }
        data = r
      }

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (data.status === 401) {

        //todo check this is not a refresh token/login/register request and not second attemtp
        // then emit event onRequireTokenRefresh
        // and subscribe to onTokenRefreshed event. send same request again in callback
        // return this.request({
        //   body,
        //   secure,
        //   path,
        //   type,
        //   query,
        //   format,
        //   baseUrl,
        //   cancelToken,
        //   ...params
        // });

        // @ts-ignore
        RootStoreContext._currentValue.accountStore.logout()
        toast.error('Час сесії скінчився')
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://alpha.test.raptom.app
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  problems = {
    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Details
     * @summary Детализация по проблеме
     * @request GET:/api/problems/{problemId}
     */
    details: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/problems/${problemId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Update
     * @summary Обновить проблему
     * @request PUT:/api/problems/{problemId}
     */
    update: (problemId: number, data: ProblemCreateDto, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/problems/${problemId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name ViewProblem
     * @summary Добавить просмотр проблемы
     * @request POST:/api/problems/{problemId}/view
     */
    viewProblem: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/problems/${problemId}/view`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Share
     * @summary Создать ссылку на проблему. Возвращает существующую, если ссылка уже создана
     * @request POST:/api/problems/{problemId}/share
     */
    share: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemShareResponseDto, any>({
        path: `/api/problems/${problemId}/share`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name SendToModeration1
     * @summary Отправить проблему на модерацию
     * @request POST:/api/problems/{problemId}/send-to-moderation
     */
    sendToModeration1: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/problems/${problemId}/send-to-moderation`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Resolve1
     * @summary Уведомить о готовности решить проблему
     * @request POST:/api/problems/{problemId}/resolve
     */
    resolve1: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/problems/${problemId}/resolve`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name RequestWithdrawal
     * @summary Запросить вывод средств
     * @request POST:/api/problems/{problemId}/request-withdrawal
     */
    requestWithdrawal: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/problems/${problemId}/request-withdrawal`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetMyPayments
     * @summary Получить платежи по проблеме авторизовано
     * @request GET:/api/problems/{problemId}/payments
     */
    getMyPayments: (
      problemId: number,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemPaymentDto, any>({
        path: `/api/problems/${problemId}/payments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name CreatePayment
     * @summary Создать платеж по проблеме
     * @request POST:/api/problems/{problemId}/payments
     */
    createPayment: (
      problemId: number,
      data: ProblemPaymentCreateDto,
      query?: {
        /** @default false */
        binancePay?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemPaymentDto, any>({
        path: `/api/problems/${problemId}/payments`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Search
     * @summary Информация о файлах загруженных в проблему
     * @request GET:/api/problems/{problemId}/files
     */
    search: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemFileDto[], any>({
        path: `/api/problems/${problemId}/files`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name UploadFile
     * @summary Добавить файл в проблему
     * @request POST:/api/problems/{problemId}/files
     */
    uploadFile: (
      problemId: number,
      query: {
        type: "IMAGE" | "VIDEO" | "FILE";
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemFileDto, any>({
        path: `/api/problems/${problemId}/files`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name PublicEvents1
     * @summary События проблемы
     * @request GET:/api/problems/{problemId}/events
     */
    publicEvents1: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemEventDto[], any>({
        path: `/api/problems/${problemId}/events`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name CreateEvent1
     * @summary Создать событие
     * @request POST:/api/problems/{problemId}/events
     */
    createEvent1: (problemId: number, data: ProblemEventAppCreateDto, params: RequestParams = {}) =>
      this.request<ProblemEventDto, any>({
        path: `/api/problems/${problemId}/events`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetProblemComments1
     * @summary Получить комментарии проблемы
     * @request GET:/api/problems/{problemId}/comments
     */
    getProblemComments1: (
      problemId: number,
      query: {
        /** @format int32 */
        parentCommentId?: number;
        /** @format int32 */
        page: number;
        /**
         * @format int32
         * @min 1
         * @max 10
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemCommentDto, any>({
        path: `/api/problems/${problemId}/comments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name CreateProblemComment1
     * @summary Добавить комментарий к проблеме
     * @request POST:/api/problems/{problemId}/comments
     */
    createProblemComment1: (problemId: number, data: ProblemCommentCreateDto, params: RequestParams = {}) =>
      this.request<ProblemCommentDto, any>({
        path: `/api/problems/${problemId}/comments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name VoteSurvey1
     * @summary Проголосовать в опросе
     * @request POST:/api/problems/surveys/{problemSurveyId}/vote
     */
    voteSurvey1: (
      problemSurveyId: number,
      query: {
        /** @format int64 */
        optionId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemSurveyExtendedDto, any>({
        path: `/api/problems/surveys/${problemSurveyId}/vote`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetSupported
     * @summary Получить список проблем которые поддерживает авторизованный пользователь
     * @request GET:/api/problems/supported
     */
    getSupported: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 20
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemDto, any>({
        path: `/api/problems/supported`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name AddSupported
     * @summary Поддержать проблему
     * @request POST:/api/problems/supported
     */
    addSupported: (
      query: {
        /** @format int32 */
        problemId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/problems/supported`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeleteSupported
     * @summary Отказаться от поддержки проблемы
     * @request DELETE:/api/problems/supported
     */
    deleteSupported: (
      query: {
        /** @format int32 */
        problemId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/problems/supported`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name MarkAsSeen
     * @summary Отметить уведомления как просмотренные
     * @request POST:/api/problems/notifications/seen
     */
    markAsSeen: (
      query: {
        ids: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/problems/notifications/seen`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name UploadEventFile1
     * @summary Добавить файл в запись
     * @request POST:/api/problems/events/{problemEventId}/files
     */
    uploadEventFile1: (
      problemEventId: number,
      query: {
        type: "IMAGE" | "VIDEO" | "FILE";
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemFileDto, any>({
        path: `/api/problems/events/${problemEventId}/files`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Create1
     * @summary Создать проблему
     * @request POST:/api/problems
     */
    create1: (data: ProblemCreateDto, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/problems`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetUsers1
     * @summary Получить список пользователей которые поддержали проблему
     * @request GET:/api/problems/{problemId}/users
     */
    getUsers1: (
      problemId: number,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemUserExtendedDto, any>({
        path: `/api/problems/${problemId}/users`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetOneSurvey
     * @summary Получить детализацию по голосованию
     * @request GET:/api/problems/surveys/{problemSurveyId}
     */
    getOneSurvey: (problemSurveyId: number, params: RequestParams = {}) =>
      this.request<ProblemSurveyExtendedDto, any>({
        path: `/api/problems/surveys/${problemSurveyId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetActiveSurveys
     * @summary Получить список текущих голосований
     * @request GET:/api/problems/surveys
     */
    getActiveSurveys: (params: RequestParams = {}) =>
      this.request<ProblemSurveyExtendedDto[], any>({
        path: `/api/problems/surveys`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetReport1
     * @summary Получить информацию по общему количеству проблем
     * @request GET:/api/problems/report/total
     */
    getReport1: (params: RequestParams = {}) =>
      this.request<ProblemTotalReportDto, any>({
        path: `/api/problems/report/total`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Latest
     * @summary Последние созданные платежи
     * @request GET:/api/problems/payments/latest
     */
    latest: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemPaymentInfoDto[], any>({
        path: `/api/problems/payments/latest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetAllMyPayments
     * @summary Получить платежи по всем проблемам
     * @request GET:/api/problems/payments
     */
    getAllMyPayments: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemPaymentDto, any>({
        path: `/api/problems/payments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetSummary
     * @summary Информация об уведомлениях пользователя
     * @request GET:/api/problems/notifications/summary
     */
    getSummary: (params: RequestParams = {}) =>
      this.request<ProblemNotificationsSummaryDto, any>({
        path: `/api/problems/notifications/summary`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetMy
     * @summary Получить свои уведомления
     * @request GET:/api/problems/notifications/my
     */
    getMy: (
      query: {
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemNotificationDto, any>({
        path: `/api/problems/notifications/my`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Nearest1
     * @summary Список проблем ближайших к указанным координатам
     * @request GET:/api/problems/nearest
     */
    nearest1: (
      query: {
        /** @format double */
        lat: number;
        /** @format double */
        lon: number;
        /** @format double */
        latDelta: number;
        /** @format double */
        lonDelta: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemExtendedDto[], any>({
        path: `/api/problems/nearest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name MyProblems1
     * @summary Список проблем, созданных авторизованым пользователем
     * @request GET:/api/problems/my
     */
    myProblems1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 20
         */
        size?: number;
        /** @default false */
        includeDrafts?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemDto, any>({
        path: `/api/problems/my`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Latest2
     * @summary Последние созданные проблемы
     * @request GET:/api/problems/latest
     */
    latest2: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemExtendedDto[], any>({
        path: `/api/problems/latest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Latest4
     * @summary Последние созданные события проблем
     * @request GET:/api/problems/events/latest
     */
    latest4: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemEventDto[], any>({
        path: `/api/problems/events/latest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetConfig1
     * @summary Настройки мобильного приложения
     * @request GET:/api/problems/config
     */
    getConfig1: (params: RequestParams = {}) =>
      this.request<ProblemAppConfigDto, any>({
        path: `/api/problems/config`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetCategories1
     * @summary Получить список активных категорий для создания проблемы
     * @request GET:/api/problems/categories
     */
    getCategories1: (params: RequestParams = {}) =>
      this.request<ProblemCategoryDto[], any>({
        path: `/api/problems/categories`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetAllLight
     * @summary Легковесный метод для быстрой загрузки базовой информации о всех проблемах
     * @request GET:/api/problems/all/light
     */
    getAllLight: (
      query?: {
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemLightDto, any>({
        path: `/api/problems/all/light`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Общедоступные методы проблем
     * @name GetProblemPage
     * @summary Публичная страница проблемы с описанием
     * @request GET:/api/problems/{problemId}/html
     */
    getProblemPage: (problemId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/problems/${problemId}/html`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeletePayment1
     * @summary Удаление неуспешного платежа
     * @request DELETE:/api/problems/{problemId}/payments/{problemPaymentId}
     */
    deletePayment1: (problemId: number, problemPaymentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/problems/${problemId}/payments/${problemPaymentId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeleteFile1
     * @summary Удалить файл с проблемы
     * @request DELETE:/api/problems/{problemId}/files/{fileId}
     */
    deleteFile1: (problemId: number, fileId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/problems/${problemId}/files/${fileId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeleteNewsComment1
     * @summary Удалить комментарий к проблеме
     * @request DELETE:/api/problems/{problemId}/comments/{commentId}
     */
    deleteNewsComment1: (problemId: number, commentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/problems/${problemId}/comments/${commentId}`,
        method: "DELETE",
        ...params,
      }),
  };
  v1 = {
    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Details1
     * @summary Детализация по проблеме
     * @request GET:/api/v1/app/problems/{problemId}
     */
    details1: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/v1/app/problems/${problemId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Update1
     * @summary Обновить проблему
     * @request PUT:/api/v1/app/problems/{problemId}
     */
    update1: (problemId: number, data: ProblemCreateDto, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/v1/app/problems/${problemId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name ViewProblem1
     * @summary Добавить просмотр проблемы
     * @request POST:/api/v1/app/problems/{problemId}/view
     */
    viewProblem1: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/${problemId}/view`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Share1
     * @summary Создать ссылку на проблему. Возвращает существующую, если ссылка уже создана
     * @request POST:/api/v1/app/problems/{problemId}/share
     */
    share1: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemShareResponseDto, any>({
        path: `/api/v1/app/problems/${problemId}/share`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name SendToModeration
     * @summary Отправить проблему на модерацию
     * @request POST:/api/v1/app/problems/{problemId}/send-to-moderation
     */
    sendToModeration: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/${problemId}/send-to-moderation`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Resolve
     * @summary Уведомить о готовности решить проблему
     * @request POST:/api/v1/app/problems/{problemId}/resolve
     */
    resolve: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/${problemId}/resolve`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name RequestWithdrawal1
     * @summary Запросить вывод средств
     * @request POST:/api/v1/app/problems/{problemId}/request-withdrawal
     */
    requestWithdrawal1: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/${problemId}/request-withdrawal`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetMyPayments1
     * @summary Получить платежи по проблеме авторизовано
     * @request GET:/api/v1/app/problems/{problemId}/payments
     */
    getMyPayments1: (
      problemId: number,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemPaymentDto, any>({
        path: `/api/v1/app/problems/${problemId}/payments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name CreatePayment1
     * @summary Создать платеж по проблеме
     * @request POST:/api/v1/app/problems/{problemId}/payments
     */
    createPayment1: (
      problemId: number,
      data: ProblemPaymentCreateDto,
      query?: {
        /** @default false */
        binancePay?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemPaymentDto, any>({
        path: `/api/v1/app/problems/${problemId}/payments`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Search1
     * @summary Информация о файлах загруженных в проблему
     * @request GET:/api/v1/app/problems/{problemId}/files
     */
    search1: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemFileDto[], any>({
        path: `/api/v1/app/problems/${problemId}/files`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name UploadFile1
     * @summary Добавить файл в проблему
     * @request POST:/api/v1/app/problems/{problemId}/files
     */
    uploadFile1: (
      problemId: number,
      query: {
        type: "IMAGE" | "VIDEO" | "FILE";
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemFileDto, any>({
        path: `/api/v1/app/problems/${problemId}/files`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name PublicEvents
     * @summary События проблемы
     * @request GET:/api/v1/app/problems/{problemId}/events
     */
    publicEvents: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemEventDto[], any>({
        path: `/api/v1/app/problems/${problemId}/events`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name CreateEvent
     * @summary Создать событие
     * @request POST:/api/v1/app/problems/{problemId}/events
     */
    createEvent: (problemId: number, data: ProblemEventAppCreateDto, params: RequestParams = {}) =>
      this.request<ProblemEventDto, any>({
        path: `/api/v1/app/problems/${problemId}/events`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetProblemComments
     * @summary Получить комментарии проблемы
     * @request GET:/api/v1/app/problems/{problemId}/comments
     */
    getProblemComments: (
      problemId: number,
      query: {
        /** @format int32 */
        parentCommentId?: number;
        /** @format int32 */
        page: number;
        /**
         * @format int32
         * @min 1
         * @max 10
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemCommentDto, any>({
        path: `/api/v1/app/problems/${problemId}/comments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name CreateProblemComment
     * @summary Добавить комментарий к проблеме
     * @request POST:/api/v1/app/problems/{problemId}/comments
     */
    createProblemComment: (problemId: number, data: ProblemCommentCreateDto, params: RequestParams = {}) =>
      this.request<ProblemCommentDto, any>({
        path: `/api/v1/app/problems/${problemId}/comments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name VoteSurvey
     * @summary Проголосовать в опросе
     * @request POST:/api/v1/app/problems/surveys/{problemSurveyId}/vote
     */
    voteSurvey: (
      problemSurveyId: number,
      query: {
        /** @format int64 */
        optionId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemSurveyExtendedDto, any>({
        path: `/api/v1/app/problems/surveys/${problemSurveyId}/vote`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetSupported1
     * @summary Получить список проблем которые поддерживает авторизованный пользователь
     * @request GET:/api/v1/app/problems/supported
     */
    getSupported1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 20
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemDto, any>({
        path: `/api/v1/app/problems/supported`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name AddSupported1
     * @summary Поддержать проблему
     * @request POST:/api/v1/app/problems/supported
     */
    addSupported1: (
      query: {
        /** @format int32 */
        problemId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/supported`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeleteSupported1
     * @summary Отказаться от поддержки проблемы
     * @request DELETE:/api/v1/app/problems/supported
     */
    deleteSupported1: (
      query: {
        /** @format int32 */
        problemId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/supported`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name MarkAsSeen1
     * @summary Отметить уведомления как просмотренные
     * @request POST:/api/v1/app/problems/notifications/seen
     */
    markAsSeen1: (
      query: {
        ids: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/notifications/seen`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name UploadEventFile
     * @summary Добавить файл в запись
     * @request POST:/api/v1/app/problems/events/{problemEventId}/files
     */
    uploadEventFile: (
      problemEventId: number,
      query: {
        type: "IMAGE" | "VIDEO" | "FILE";
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemFileDto, any>({
        path: `/api/v1/app/problems/events/${problemEventId}/files`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Create
     * @summary Создать проблему
     * @request POST:/api/v1/app/problems
     */
    create: (data: ProblemCreateDto, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/v1/app/problems`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetUsers
     * @summary Получить список пользователей которые поддержали проблему
     * @request GET:/api/v1/app/problems/{problemId}/users
     */
    getUsers: (
      problemId: number,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemUserExtendedDto, any>({
        path: `/api/v1/app/problems/${problemId}/users`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetOneSurvey1
     * @summary Получить детализацию по голосованию
     * @request GET:/api/v1/app/problems/surveys/{problemSurveyId}
     */
    getOneSurvey1: (problemSurveyId: number, params: RequestParams = {}) =>
      this.request<ProblemSurveyExtendedDto, any>({
        path: `/api/v1/app/problems/surveys/${problemSurveyId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetActiveSurveys1
     * @summary Получить список текущих голосований
     * @request GET:/api/v1/app/problems/surveys
     */
    getActiveSurveys1: (params: RequestParams = {}) =>
      this.request<ProblemSurveyExtendedDto[], any>({
        path: `/api/v1/app/problems/surveys`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetReport
     * @summary Получить информацию по общему количеству проблем
     * @request GET:/api/v1/app/problems/report/total
     */
    getReport: (params: RequestParams = {}) =>
      this.request<ProblemTotalReportDto, any>({
        path: `/api/v1/app/problems/report/total`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Latest1
     * @summary Последние созданные платежи
     * @request GET:/api/v1/app/problems/payments/latest
     */
    latest1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemPaymentInfoDto[], any>({
        path: `/api/v1/app/problems/payments/latest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetAllMyPayments1
     * @summary Получить платежи по всем проблемам
     * @request GET:/api/v1/app/problems/payments
     */
    getAllMyPayments1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemPaymentDto, any>({
        path: `/api/v1/app/problems/payments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetSummary1
     * @summary Информация об уведомлениях пользователя
     * @request GET:/api/v1/app/problems/notifications/summary
     */
    getSummary1: (params: RequestParams = {}) =>
      this.request<ProblemNotificationsSummaryDto, any>({
        path: `/api/v1/app/problems/notifications/summary`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetMy1
     * @summary Получить свои уведомления
     * @request GET:/api/v1/app/problems/notifications/my
     */
    getMy1: (
      query: {
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemNotificationDto, any>({
        path: `/api/v1/app/problems/notifications/my`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Nearest
     * @summary Список проблем ближайших к указанным координатам
     * @request GET:/api/v1/app/problems/nearest
     */
    nearest: (
      query: {
        /** @format double */
        lat: number;
        /** @format double */
        lon: number;
        /** @format double */
        latDelta: number;
        /** @format double */
        lonDelta: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemExtendedDto[], any>({
        path: `/api/v1/app/problems/nearest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name MyProblems
     * @summary Список проблем, созданных авторизованым пользователем
     * @request GET:/api/v1/app/problems/my
     */
    myProblems: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 20
         */
        size?: number;
        /** @default false */
        includeDrafts?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemDto, any>({
        path: `/api/v1/app/problems/my`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Latest3
     * @summary Последние созданные проблемы
     * @request GET:/api/v1/app/problems/latest
     */
    latest3: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemExtendedDto[], any>({
        path: `/api/v1/app/problems/latest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name Latest5
     * @summary Последние созданные события проблем
     * @request GET:/api/v1/app/problems/events/latest
     */
    latest5: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemEventDto[], any>({
        path: `/api/v1/app/problems/events/latest`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetConfig
     * @summary Настройки мобильного приложения
     * @request GET:/api/v1/app/problems/config
     */
    getConfig: (params: RequestParams = {}) =>
      this.request<ProblemAppConfigDto, any>({
        path: `/api/v1/app/problems/config`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetCategories
     * @summary Получить список активных категорий для создания проблемы
     * @request GET:/api/v1/app/problems/categories
     */
    getCategories: (params: RequestParams = {}) =>
      this.request<ProblemCategoryDto[], any>({
        path: `/api/v1/app/problems/categories`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name GetAllLight1
     * @summary Легковесный метод для быстрой загрузки базовой информации о всех проблемах
     * @request GET:/api/v1/app/problems/all/light
     */
    getAllLight1: (
      query?: {
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemLightDto, any>({
        path: `/api/v1/app/problems/all/light`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeletePayment
     * @summary Удаление неуспешного платежа
     * @request DELETE:/api/v1/app/problems/{problemId}/payments/{problemPaymentId}
     */
    deletePayment: (problemId: number, problemPaymentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/${problemId}/payments/${problemPaymentId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeleteFile
     * @summary Удалить файл с проблемы
     * @request DELETE:/api/v1/app/problems/{problemId}/files/{fileId}
     */
    deleteFile: (problemId: number, fileId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/${problemId}/files/${fileId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Проблемы. Апи для моб приложения
     * @name DeleteNewsComment
     * @summary Удалить комментарий к проблеме
     * @request DELETE:/api/v1/app/problems/{problemId}/comments/{commentId}
     */
    deleteNewsComment: (problemId: number, commentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/app/problems/${problemId}/comments/${commentId}`,
        method: "DELETE",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Пользователи
     * @name GetMe
     * @summary Информация про авторизованного пользователя
     * @request GET:/api/users/me
     */
    getMe: (params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/users/me`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пользователи
     * @name UpdateMe
     * @summary Обновить информацию про авторизованного пользователя
     * @request PUT:/api/users/me
     */
    updateMe: (data: UserEditDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/users/me`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пользователи
     * @name DeleteMe
     * @summary Удалить авторизованного пользователя
     * @request DELETE:/api/users/me
     */
    deleteMe: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/me`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пользователи
     * @name UploadPhoto
     * @summary Загрузить аватар пользователя
     * @request PUT:/api/users/me/photo
     */
    uploadPhoto: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserDto, any>({
        path: `/api/users/me/photo`,
        method: "PUT",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пользователи
     * @name GetUser
     * @summary Информация про пользователя
     * @request GET:/api/users/{userId}
     */
    getUser: (userId: string, params: RequestParams = {}) =>
      this.request<UserLightDto, any>({
        path: `/api/users/${userId}`,
        method: "GET",
        ...params,
      }),
  };
  posts = {
    /**
     * No description
     *
     * @tags Публикации.
     * @name GetDetails
     * @summary Детализация публикации
     * @request GET:/api/posts/{postId}
     */
    getDetails: (postId: number, params: RequestParams = {}) =>
      this.request<PostDto, any>({
        path: `/api/posts/${postId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name UpdatePost
     * @summary Редактировать свою публикацию
     * @request PUT:/api/posts/{postId}
     */
    updatePost: (postId: number, data: PostCreateDto, params: RequestParams = {}) =>
      this.request<PostDto, any>({
        path: `/api/posts/${postId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name DeletePost
     * @summary Удалить свою публикацию
     * @request DELETE:/api/posts/{postId}
     */
    deletePost: (postId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name SetPreview1
     * @summary Загрузить изображение превью
     * @request PUT:/api/posts/{postId}/preview
     */
    setPreview1: (
      postId: number,
      query: {
        /** @format int32 */
        postFileId: number;
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostDto, any>({
        path: `/api/posts/${postId}/preview`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name GetPosts
     * @summary Список публикаций
     * @request GET:/api/posts
     */
    getPosts: (
      query: {
        params: PostSearchRequestParamsDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoPostDto, any>({
        path: `/api/posts`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name CreatePost
     * @summary Создать публикацию
     * @request POST:/api/posts
     */
    createPost: (data: PostCreateDto, params: RequestParams = {}) =>
      this.request<PostDto, any>({
        path: `/api/posts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name AddSaved
     * @summary Сохранить пост в избранное для юзера
     * @request POST:/api/posts/{postId}/saved
     */
    addSaved: (postId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/saved`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name DeleteSaved
     * @summary Удалить пост из избранного у юзера
     * @request DELETE:/api/posts/{postId}/saved
     */
    deleteSaved: (postId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/saved`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name AddLike
     * @summary Поддержать пост
     * @request POST:/api/posts/{postId}/likes
     */
    addLike: (postId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/likes`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name DeleteLike
     * @summary Удалить поддержку
     * @request DELETE:/api/posts/{postId}/likes
     */
    deleteLike: (postId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/likes`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Файлы публикаций
     * @name GetFiles
     * @summary Получить файлы публикации
     * @request GET:/api/posts/{postId}/files
     */
    getFiles: (postId: number, params: RequestParams = {}) =>
      this.request<PostFileLightDto[], any>({
        path: `/api/posts/${postId}/files`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Файлы публикаций
     * @name UploadFile2
     * @summary Загрузить файл к публикации
     * @request POST:/api/posts/{postId}/files
     */
    uploadFile2: (
      postId: number,
      query: {
        type: "IMAGE" | "VIDEO" | "PDF" | "OTHER";
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostFileLightDto, any>({
        path: `/api/posts/${postId}/files`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name ComplainPost
     * @summary Пожаловаться на публикацию
     * @request POST:/api/posts/{postId}/complain
     */
    complainPost: (postId: number, data: ComplainCreateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/complain`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Коментарии к публикациям
     * @name GetPostComments
     * @summary Получить комментарии публикации
     * @request GET:/api/posts/{postId}/comments
     */
    getPostComments: (
      postId: number,
      query?: {
        /** @format int32 */
        parentCommentId?: number;
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 20
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoPostCommentDto, any>({
        path: `/api/posts/${postId}/comments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Коментарии к публикациям
     * @name CreateComment
     * @summary Создать коментарий
     * @request POST:/api/posts/{postId}/comments
     */
    createComment: (postId: number, data: PostCommentCreateDto, params: RequestParams = {}) =>
      this.request<PostCommentDto, any>({
        path: `/api/posts/${postId}/comments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Коментарии к публикациям
     * @name ComplainPost1
     * @summary Пожаловаться на коментарий
     * @request POST:/api/posts/{postId}/comments/{commentId}/complain
     */
    complainPost1: (postId: number, commentId: number, data: ComplainCreateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/comments/${commentId}/complain`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name AddView
     * @summary Отправить информацию про просмотр постов
     * @request POST:/api/posts/view
     */
    addView: (data: PostViewRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/view`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Предпочтения по публикациям
     * @name GetPreferences
     * @summary Получить предпочтения по публикациям авторизованного пользователя
     * @request GET:/api/posts/preferences
     */
    getPreferences: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoPostPreferenceDto, any>({
        path: `/api/posts/preferences`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Предпочтения по публикациям
     * @name SavePreference
     * @summary Сохранить новое предпочтение
     * @request POST:/api/posts/preferences
     */
    savePreference: (data: PostPreferenceCreateRequestDto, params: RequestParams = {}) =>
      this.request<PostPreferenceDto, any>({
        path: `/api/posts/preferences`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации.
     * @name GetSavedByUser
     * @summary Избранные публикации текущего пользователя
     * @request GET:/api/posts/saved
     */
    getSavedByUser: (
      query: {
        params: SavedPostSearchRequestParamsDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoPostDto, any>({
        path: `/api/posts/saved`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Файлы публикаций
     * @name DeleteFile2
     * @summary Удалить файл с публикации
     * @request DELETE:/api/posts/{postId}/files/{postFileId}
     */
    deleteFile2: (postId: number, postFileId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/files/${postFileId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Коментарии к публикациям
     * @name DeleteComment
     * @summary Удалить комментарий
     * @request DELETE:/api/posts/{postId}/comments/{commentId}
     */
    deleteComment: (postId: number, commentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/${postId}/comments/${commentId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Публикации. Предпочтения по публикациям
     * @name DeletePreference
     * @summary Удалить предпочтение по публикациям
     * @request DELETE:/api/posts/preferences/{postPreferenceId}
     */
    deletePreference: (postPreferenceId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/posts/preferences/${postPreferenceId}`,
        method: "DELETE",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name GetOne
     * @summary Детальная информация по проблеме
     * @request GET:/api/admin/problems/{problemId}
     */
    getOne: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemExtendedDto, any>({
        path: `/api/admin/problems/${problemId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name Update2
     * @summary Обновить проблему
     * @request PUT:/api/admin/problems/{problemId}
     */
    update2: (problemId: number, data: ProblemCreateDto, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/admin/problems/${problemId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Пользователи участники проблем
     * @name AddProblemUser
     * @summary Добавление участника проблемы
     * @request PUT:/api/admin/problems/{problemId}/user
     */
    addProblemUser: (problemId: number, data: ProblemUserCreateDto, params: RequestParams = {}) =>
      this.request<ProblemUserDto, any>({
        path: `/api/admin/problems/${problemId}/user`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Пользователи участники проблем
     * @name AddProblemUserByUserId
     * @summary Добавление участника проблемы по его id
     * @request PUT:/api/admin/problems/{problemId}/user/{userId}
     */
    addProblemUserByUserId: (problemId: number, userId: string, params: RequestParams = {}) =>
      this.request<ProblemUserDto, any>({
        path: `/api/admin/problems/${problemId}/user/${userId}`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Пользователи участники проблем
     * @name DeleteProblemUser
     * @summary Удаление участника проблемы
     * @request DELETE:/api/admin/problems/{problemId}/user/{userId}
     */
    deleteProblemUser: (problemId: number, userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/user/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Пользователи участники проблем
     * @name UpdateOwner
     * @summary Сохранение пользователя которому принадлежит проблема
     * @request PUT:/api/admin/problems/{problemId}/owner
     */
    updateOwner: (problemId: number, data: ProblemUserCreateDto, params: RequestParams = {}) =>
      this.request<ProblemUserDto, any>({
        path: `/api/admin/problems/${problemId}/owner`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Пользователи участники проблем
     * @name UpdateOwnerByUserId
     * @summary Сохранение пользователя которому принадлежит проблема по его id
     * @request PUT:/api/admin/problems/{problemId}/owner/{userId}
     */
    updateOwnerByUserId: (problemId: number, userId: string, params: RequestParams = {}) =>
      this.request<ProblemUserDto, any>({
        path: `/api/admin/problems/${problemId}/owner/${userId}`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Журнал событий проблем
     * @name Update3
     * @summary Отредактировать запись в журнале проблемы
     * @request PUT:/api/admin/problems/{problemId}/events/{problemEventId}
     */
    update3: (problemId: number, problemEventId: number, data: ProblemEventCreateDto, params: RequestParams = {}) =>
      this.request<ProblemEventDto, any>({
        path: `/api/admin/problems/${problemId}/events/${problemEventId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name FindSurvey
     * @summary Получение опроса по id
     * @request GET:/api/admin/problems/surveys/{problemSurveyId}
     */
    findSurvey: (problemSurveyId: number, params: RequestParams = {}) =>
      this.request<ProblemSurveyExtendedDto, any>({
        path: `/api/admin/problems/surveys/${problemSurveyId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name Update4
     * @summary Редактирование опроса
     * @request PUT:/api/admin/problems/surveys/{problemSurveyId}
     */
    update4: (problemSurveyId: number, data: ProblemSurveyUpdateDto, params: RequestParams = {}) =>
      this.request<ProblemSurveyDto, any>({
        path: `/api/admin/problems/surveys/${problemSurveyId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name Delete
     * @summary Удаление опроса
     * @request DELETE:/api/admin/problems/surveys/{problemSurveyId}
     */
    delete: (problemSurveyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/surveys/${problemSurveyId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name UpdateOption
     * @summary Редактирование опции ответа
     * @request PUT:/api/admin/problems/surveys/{problemSurveyId}/options/{optionId}
     */
    updateOption: (
      problemSurveyId: number,
      optionId: number,
      data: ProblemSurveyOptionCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<ProblemSurveyDto, any>({
        path: `/api/admin/problems/surveys/${problemSurveyId}/options/${optionId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name DeleteOption
     * @summary Удаление опции опроса
     * @request DELETE:/api/admin/problems/surveys/{problemSurveyId}/options/{optionId}
     */
    deleteOption: (problemSurveyId: number, optionId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/surveys/${problemSurveyId}/options/${optionId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Категории проблем
     * @name GetDetails1
     * @summary Детализация категории по id
     * @request GET:/api/admin/problems/categories/{problemCategoryId}
     */
    getDetails1: (problemCategoryId: number, params: RequestParams = {}) =>
      this.request<ProblemCategoryDto, any>({
        path: `/api/admin/problems/categories/${problemCategoryId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Категории проблем
     * @name Update5
     * @summary Редактирование категории проблем
     * @request PUT:/api/admin/problems/categories/{problemCategoryId}
     */
    update5: (problemCategoryId: number, data: ProblemCategoryCreateDto, params: RequestParams = {}) =>
      this.request<ProblemCategoryDto, any>({
        path: `/api/admin/problems/categories/${problemCategoryId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Категории проблем
     * @name Delete1
     * @summary Удалить категорию проблем
     * @request DELETE:/api/admin/problems/categories/{problemCategoryId}
     */
    delete1: (problemCategoryId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/categories/${problemCategoryId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Публикации
     * @name UpdateStatus
     * @summary Обновить статус публикации (удалить, скрыть, восстановить)
     * @request PUT:/api/admin/posts/{postId}/status
     */
    updateStatus: (
      postId: number,
      query: {
        newStatus: "ACTIVE" | "HIDDEN" | "DELETED";
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/admin/posts/${postId}/status`,
        method: "PUT",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Тендеры
     * @name RefreshTender
     * @summary Обновить данные тендера
     * @request POST:/api/admin/tenders/{tenderId}/refresh
     */
    refreshTender: (tenderId: string, params: RequestParams = {}) =>
      this.request<TenderDto, any>({
        path: `/api/admin/tenders/${tenderId}/refresh`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name Create2
     * @summary Создать проблемы
     * @request POST:/api/admin/problems
     */
    create2: (data: ProblemCreateDto, params: RequestParams = {}) =>
      this.request<ProblemDto, any>({
        path: `/api/admin/problems`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name SetInProcess
     * @summary Отметить завершение сбора средств и отправить проблему в статус IN_PROCERSS
     * @request POST:/api/admin/problems/{problemId}/set-in-process
     */
    setInProcess: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/set-in-process`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name SendToModeration2
     * @summary Отправить проблему на модерацию
     * @request POST:/api/admin/problems/{problemId}/send-to-moderation
     */
    sendToModeration2: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/send-to-moderation`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name RejectResolver
     * @summary Отклонить запрос на решение проблемы
     * @request POST:/api/admin/problems/{problemId}/resolvers/{userId}/reject
     */
    rejectResolver: (
      problemId: number,
      userId: string,
      data: ProblemResolverChangeStatusDto,
      params: RequestParams = {},
    ) =>
      this.request<ProblemResolverDto, any>({
        path: `/api/admin/problems/${problemId}/resolvers/${userId}/reject`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name ApproveResolver
     * @summary Подтвердить запрос на решение проблемы
     * @request POST:/api/admin/problems/{problemId}/resolvers/{userId}/approve
     */
    approveResolver: (
      problemId: number,
      userId: string,
      data: ProblemResolverChangeStatusDto,
      params: RequestParams = {},
    ) =>
      this.request<ProblemResolverDto, any>({
        path: `/api/admin/problems/${problemId}/resolvers/${userId}/approve`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name Reject
     * @summary Отклонить проблему созданную пользователем
     * @request POST:/api/admin/problems/{problemId}/reject
     */
    reject: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/reject`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Файлы проблем
     * @name Search2
     * @summary Информация о файлах загруженных в проблему
     * @request GET:/api/admin/problems/{problemId}/files
     */
    search2: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemFileDto[], any>({
        path: `/api/admin/problems/${problemId}/files`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Файлы проблем
     * @name UploadFile3
     * @summary Добавить файл в проблему
     * @request POST:/api/admin/problems/{problemId}/files
     */
    uploadFile3: (
      problemId: number,
      query: {
        type: "IMAGE" | "VIDEO" | "FILE";
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemFileDto, any>({
        path: `/api/admin/problems/${problemId}/files`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Журнал событий проблем
     * @name GetAll
     * @summary Получить журнал по проблеме
     * @request GET:/api/admin/problems/{problemId}/events
     */
    getAll: (problemId: number, params: RequestParams = {}) =>
      this.request<ProblemEventDto[], any>({
        path: `/api/admin/problems/${problemId}/events`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Журнал событий проблем
     * @name Create3
     * @summary Создать запись в журнале проблемы
     * @request POST:/api/admin/problems/{problemId}/events
     */
    create3: (problemId: number, data: ProblemEventCreateDto, params: RequestParams = {}) =>
      this.request<ProblemEventDto, any>({
        path: `/api/admin/problems/${problemId}/events`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Журнал событий проблем
     * @name GetEventFiles
     * @summary Список загруженных в запись файлов
     * @request GET:/api/admin/problems/{problemId}/events/{problemEventId}/files
     */
    getEventFiles: (problemEventId: number, problemId: number, params: RequestParams = {}) =>
      this.request<ProblemFileDto[], any>({
        path: `/api/admin/problems/${problemId}/events/${problemEventId}/files`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Журнал событий проблем
     * @name UploadEventFile2
     * @summary Добавить файл в запись
     * @request POST:/api/admin/problems/{problemId}/events/{problemEventId}/files
     */
    uploadEventFile2: (
      problemId: number,
      problemEventId: number,
      query: {
        type: "IMAGE" | "VIDEO" | "FILE";
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProblemFileDto, any>({
        path: `/api/admin/problems/${problemId}/events/${problemEventId}/files`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name Archive
     * @summary Архивировать проблему
     * @request POST:/api/admin/problems/{problemId}/archive
     */
    archive: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/archive`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name Approve
     * @summary Подтвердить проблему отправленную на модерацию
     * @request POST:/api/admin/problems/{problemId}/approve
     */
    approve: (problemId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/approve`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name Create4
     * @summary Создание опроса
     * @request POST:/api/admin/problems/surveys
     */
    create4: (data: ProblemSurveyCreateDto, params: RequestParams = {}) =>
      this.request<ProblemSurveyDto, any>({
        path: `/api/admin/problems/surveys`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name AddOption
     * @summary Добавление опции ответа
     * @request POST:/api/admin/problems/surveys/{problemSurveyId}/options
     */
    addOption: (problemSurveyId: number, data: ProblemSurveyOptionCreateDto, params: RequestParams = {}) =>
      this.request<ProblemSurveyDto, any>({
        path: `/api/admin/problems/surveys/${problemSurveyId}/options`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Категории проблем
     * @name Create5
     * @summary Создание категории проблем
     * @request POST:/api/admin/problems/categories
     */
    create5: (data: ProblemCategoryCreateDto, params: RequestParams = {}) =>
      this.request<ProblemCategoryDto, any>({
        path: `/api/admin/problems/categories`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Публикации. Жалобы
     * @name RejectComplain
     * @summary Отклонить жалобу
     * @request POST:/api/admin/posts/complains/{complainId}/reject
     */
    rejectComplain: (complainId: number, params: RequestParams = {}) =>
      this.request<PostComplainDto, any>({
        path: `/api/admin/posts/complains/${complainId}/reject`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Авторизация/Регистрация для администраторов
     * @name RegisterEmail1
     * @summary Регистрация пользователя админки по электронной почте
     * @request POST:/api/admin/auth/register/email
     */
    registerEmail1: (data: EmailRegisterRequestDto, params: RequestParams = {}) =>
      this.request<AdminTokenResponseDto, any>({
        path: `/api/admin/auth/register/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Авторизация/Регистрация для администраторов
     * @name LoginByEmail1
     * @summary Авторизация пользователя админки по почте и паролю
     * @request POST:/api/admin/auth/login/email
     */
    loginByEmail1: (data: AdminLoginRequestDto, params: RequestParams = {}) =>
      this.request<AdminTokenResponseDto, any>({
        path: `/api/admin/auth/login/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Тендеры
     * @name GetTenders
     * @summary Получить список тендеров
     * @request GET:/api/admin/tenders
     */
    getTenders: (
      query:  TenderSearchRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<PageDtoTenderDto, any>({
        path: `/api/admin/tenders`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Тендеры
     * @name GetTender
     * @summary Получить тендер по id
     * @request GET:/api/admin/tenders/{tenderId}
     */
    getTender: (tenderId: string, params: RequestParams = {}) =>
      this.request<TenderDto, any>({
        path: `/api/admin/tenders/${tenderId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Пользователи участники проблем
     * @name GetProblemUsers
     * @summary Выборка пользователей проблем
     * @request GET:/api/admin/problems/{problemId}/users
     */
    getProblemUsers: (
      problemId: number,
      query: {
        pageRequest: PageRequestDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemUserDto, any>({
        path: `/api/admin/problems/${problemId}/users`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name GetResolvers
     * @summary Получить список людей которые откликнулись на проблему по id проблемы
     * @request GET:/api/admin/problems/{problemId}/resolvers
     */
    getResolvers: (
      problemId: number,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemResolverDto, any>({
        path: `/api/admin/problems/${problemId}/resolvers`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Платежи
     * @name GetProblemPayments
     * @summary Платежи по проблеме
     * @request GET:/api/admin/problems/{problemId}/payments
     */
    getProblemPayments: (
      problemId: number,
      query: {
        /** @format int32 */
        page: number;
        /**
         * @format int32
         * @max 50
         */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemPaymentDto, any>({
        path: `/api/admin/problems/${problemId}/payments`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Опросы
     * @name GetSurveys
     * @summary Выборка для таблицы опросов
     * @request GET:/api/admin/problems/surveys/search
     */
    getSurveys: (
      query: {
        request: PageRequestDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemSurveyExtendedDto, any>({
        path: `/api/admin/problems/surveys/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name Search3
     * @summary Выборка и фильтрация проблем
     * @request GET:/api/admin/problems/search
     */
    search3: (
      query:  PageRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemDto, any>({
        path: `/api/admin/problems/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name SearchResolvers
     * @summary Получить список людей которые откликнулись на проблемы
     * @request GET:/api/admin/problems/resolvers/search
     */
    searchResolvers: (
      query: {
        requestDto: PageRequestDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemResolverDto, any>({
        path: `/api/admin/problems/resolvers/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name SearchResolvers1
     * @summary Получить список людей которые откликнулись на проблемы
     * @request GET:/api/admin/problems/resolvers
     */
    searchResolvers1: (
      query: {
        requestDto: PageRequestDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemResolverDto, any>({
        path: `/api/admin/problems/resolvers`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Платежи
     * @name GetAllProblemPayments
     * @summary Выборка платежей по проблемам
     * @request GET:/api/admin/problems/payments/search
     */
    getAllProblemPayments: (
      query: {
        pageRequest: PageRequestDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemPaymentDto, any>({
        path: `/api/admin/problems/payments/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name SearchProblemCommentComments
     * @summary Постраничная выборка всех коментариев к проблемам
     * @request GET:/api/admin/problems/comments/search
     */
    searchProblemCommentComments: (
      query: {
        pageRequestDto: PageRequestDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemCommentDto, any>({
        path: `/api/admin/problems/comments/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Категории проблем
     * @name Search4
     * @summary Выборка и фильтрация категорий проблем
     * @request GET:/api/admin/problems/categories/search
     */
    search4: (
      query: {
        request: PageRequestDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoProblemCategoryDto, any>({
        path: `/api/admin/problems/categories/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Публикации
     * @name GetPosts11
     * @summary Выбрать сразу несколько постов по ids
     * @request GET:/api/admin/posts
     */
    getPosts11: (
      query: {
        params: AdminPostSearchParamsDto;
        postIds: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoAdminPostDto, any>({
        path: `/api/admin/posts`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Публикации
     * @name GetOne1
     * @summary Получить один пост по id
     * @request GET:/api/admin/posts/{postId}
     */
    getOne1: (postId: number, params: RequestParams = {}) =>
      this.request<AdminPostDto, any>({
        path: `/api/admin/posts/${postId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Публикации. Жалобы
     * @name GetComplainsPage
     * @summary Получить список жалоб по публикации
     * @request GET:/api/admin/posts/{postId}/complains
     */
    getComplainsPage: (
      postId: number,
      query: {
        /** @default true */
        includeUsers?: boolean;
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostComplainResponseDto, any>({
        path: `/api/admin/posts/${postId}/complains`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Публикации
     * @name GetPosts1
     * @summary Выборка постов с необработанными жалобами
     * @request GET:/api/admin/posts/with-complains
     */
    getPosts1: (
      query: {
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDtoAdminPostDto, any>({
        path: `/api/admin/posts/with-complains`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Публикации. Жалобы
     * @name GetCommentComplains
     * @summary Получить список жалоб по комментарию
     * @request GET:/api/admin/posts/comments/{postCommentId}/complains
     */
    getCommentComplains: (
      postCommentId: number,
      query: {
        /** @default true */
        includeUsers?: boolean;
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostComplainResponseDto, any>({
        path: `/api/admin/posts/comments/${postCommentId}/complains`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Администраторы
     * @name GetMe1
     * @summary Информация про авторизованного пользователя
     * @request GET:/api/admin/admins/me
     */
    getMe1: (params: RequestParams = {}) =>
      this.request<AdminUserDto, any>({
        path: `/api/admin/admins/me`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Файлы проблем
     * @name DeleteFile3
     * @summary Удалить файл с проблемы
     * @request DELETE:/api/admin/problems/{problemId}/files/{problemFileId}
     */
    deleteFile3: (problemId: number, problemFileId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/files/${problemFileId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы. Журнал событий проблем
     * @name DeleteEventFile
     * @summary Удалить файл с записи
     * @request DELETE:/api/admin/problems/{problemId}/events/{problemEventId}/files/{fileId}
     */
    deleteEventFile: (problemId: number, problemEventId: number, fileId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/${problemId}/events/${problemEventId}/files/${fileId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin. Проблемы.
     * @name DeleteComment1
     * @summary Удалить коментарий к проблеме
     * @request DELETE:/api/admin/problems/comments/{commentId}
     */
    deleteComment1: (commentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/problems/comments/${commentId}`,
        method: "DELETE",
        ...params,
      }),
  };
  resizeImage = {
    /**
     * No description
     *
     * @tags Internal
     * @name ResizeImage
     * @request POST:/internal/resize-image
     */
    resizeImage: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/internal/resize-image`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  };
  fixupPostImages = {
    /**
     * No description
     *
     * @tags Internal
     * @name FixupPostImages
     * @request POST:/internal/fixup-post-images
     */
    fixupPostImages: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internal/fixup-post-images`,
        method: "POST",
        ...params,
      }),
  };
  wayforpay = {
    /**
     * No description
     *
     * @tags WayForPay callbacks
     * @name ProcessSuccess
     * @request POST:/api/wayforpay/callback
     */
    processSuccess: (data: string, params: RequestParams = {}) =>
      this.request<WayForPayCallbackResponse, any>({
        path: `/api/wayforpay/callback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags Уведомления
     * @name MarkNotificationsAsSeen
     * @summary Пометить уведомления как прочитанные
     * @request POST:/api/notifications/seen
     */
    markNotificationsAsSeen: (data: number[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/notifications/seen`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Уведомления
     * @name GetPosts1
     * @summary Получить уведомления
     * @request GET:/api/notifications
     */
    getPosts1: (
      query: {
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<NotificationDto[], any>({
        path: `/api/notifications`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Уведомления
     * @name GetNotificationsInfo
     * @summary Получить информацию о уведомлениях
     * @request GET:/api/notifications/info
     */
    getNotificationsInfo: (params: RequestParams = {}) =>
      this.request<NotificationsInfoDto, any>({
        path: `/api/notifications/info`,
        method: "GET",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Авторизация/Регистрация пользователей
     * @name Refresh
     * @request POST:/api/auth/token/refresh
     */
    refresh: (data: RefreshTokenRequestDto, params: RequestParams = {}) =>
      this.request<TokenResponseDto, any>({
        path: `/api/auth/token/refresh`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация/Регистрация пользователей
     * @name RegisterEmail
     * @summary Регистрация пользователя по электронной почте
     * @request POST:/api/auth/register/email
     */
    registerEmail: (data: EmailRegisterRequestDto, params: RequestParams = {}) =>
      this.request<UserTokenResponseDto, any>({
        path: `/api/auth/register/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация/Регистрация пользователей
     * @name LoginByFirebase
     * @summary Авторизация пользователя по токену из firebase
     * @request POST:/api/auth/login/firebase
     */
    loginByFirebase: (data: FirebaseLoginRequestDto, params: RequestParams = {}) =>
      this.request<UserTokenResponseDto, any>({
        path: `/api/auth/login/firebase`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация/Регистрация пользователей
     * @name LoginByEmail
     * @summary Авторизация пользователя по почте и паролю
     * @request POST:/api/auth/login/email
     */
    loginByEmail: (data: UserEmailLoginRequestDto, params: RequestParams = {}) =>
      this.request<UserTokenResponseDto, any>({
        path: `/api/auth/login/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  transactions = {
    /**
     * No description
     *
     * @tags Transactions
     * @name GetPaymentForm
     * @summary Get payment form by transaction id
     * @request GET:/api/transactions/{transactionId}/form
     */
    getPaymentForm: (
      transactionId: string,
      query: {
        "success-url": string;
        "failure-url": string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/transactions/${transactionId}/form`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name TestRedirect
     * @summary Test redirect url
     * @request GET:/api/transactions/test-redirect
     */
    testRedirect: (
      query?: {
        "return-url"?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/transactions/test-redirect`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  transaction = {
    /**
     * No description
     *
     * @tags Transactions
     * @name GetPaymentForm1
     * @summary Get payment form by transaction id
     * @request GET:/api/transaction/{transactionId}/form
     */
    getPaymentForm1: (
      transactionId: string,
      query: {
        "success-url": string;
        "failure-url": string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/transaction/${transactionId}/form`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name TestRedirect1
     * @summary Test redirect url
     * @request GET:/api/transaction/test-redirect
     */
    testRedirect1: (
      query?: {
        "return-url"?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/transaction/test-redirect`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  status = {
    /**
     * No description
     *
     * @tags Информация про апи
     * @name Ping
     * @request GET:/api/status/ping
     */
    ping: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/status/ping`,
        method: "GET",
        ...params,
      }),
  };
}
