import React, { useContext, useEffect } from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../stores/rootStore'
import { useNavigate } from 'react-router-dom'

const HomePage: React.FC = () => {
  const { accountStore } = useContext(RootStoreContext)
  const navigate = useNavigate()

  const isAuthenticated = accountStore.getIsAuthenticated
  // const authData = accountStore.getAuthData;

  // useEffect(() => {
  //   accountStore.initialize()
  // }, [accountStore])

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    } else {
      // navigate('/complain')
    }
  }, [isAuthenticated, navigate])

  return (
    <div className={styles.wrapper}>
      <p>Home page</p>
    </div>
  )
}

export default observer(HomePage)
