import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 1000)
  }, [navigate])

  return <div>
    page not found
  </div>
}

export default NotFoundPage
