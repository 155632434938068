import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../stores/rootStore'
import { useNavigate } from 'react-router-dom'
import { mainApi } from '../../api'
import { toast } from 'react-toastify'
import Typography from '@mui/material/Typography'
import { Grid, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const Login: React.FC = ({ ...props }) => {
  const innerWidth = window.innerWidth
  const BASE_MODAL_WIDTH = 500
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const { accountStore } = useContext(RootStoreContext)
  const navigate = useNavigate()

  const loginUser = useCallback(() => {
    setLoading(true)
    mainApi.admin.loginByEmail1({ email, password })
      .then(({ data }) => {
        accountStore.setAuthData(data)
        setTimeout(() => {
          navigate('/')
        }, 100)
      })
      .catch((e: any) => {
        console.log(e)
        toast('Помилка')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [email, accountStore, navigate, password])

  useEffect(() => {
    accountStore.initialize()
  }, [accountStore])

  return (
    <>
      <Box className={styles.wrapper} style={{ width: innerWidth < BASE_MODAL_WIDTH ? innerWidth - 40 : BASE_MODAL_WIDTH }} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Авторизація
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1, mb: 1 }}>
          Введіть логін та пароль входу
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              value={email}
              onChange={(val) => {
                setEmail(val.target.value)
              }}
              required
              fullWidth={true}
              id="outlined-required"
              label="Email"
              />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              required
              fullWidth={true}
              onChange={(val) => {
                setPassword(val.target.value)
              }}
              value={password}
              type={'password'}
              id="outlined-required"
              label="Пароль"
              />
          </Grid>
          <Grid item sm={4} xs={12} style={{ order: innerWidth < BASE_MODAL_WIDTH ? 1 : 0 }}>
            <Button variant="text"
              fullWidth={true}
              onClick={() => {
                setEmail('')
                setPassword('')
              }}
              >Очистити</Button>
          </Grid>
          <Grid item sm={8} xs={12}>
            <Button variant="contained"
              fullWidth={true}
              onClick={loginUser}
              disabled={loading}>Увійти</Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default observer(Login)
